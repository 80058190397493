import { AlloyClaim, ApiPolicyRequest, ApiUserSessionRequest, CancellationRequest, GapClaim, Photo, SmartClaim, TyreClaim, WarrantyClaim } from "../ApiTypes"
import * as ImageManipulator from 'expo-image-manipulator';
import each from 'async/each';
import {decode as atob, encode as btoa} from 'base-64'
import { Platform } from 'react-native'

export default class Api {
  accessToken: string
  apiBase: string
  password: string
  sessionToken: string
  standardTimeout: number
  urlBase: string
  username: string
  jobid: string
  ClientSecret: string

  constructor () {
    this.urlBase = 'https://eat.savagemacbeth.com/api/'
    this.apiBase = this.urlBase + 'api/'
    this.username = ''
    this.password = ''
    this.standardTimeout = 30 * 1000

    // OAuth token
    this.accessToken = ''
    // Customer session token
    this.sessionToken = ''
	this.jobid = ''
	this.booking_postcode = ''
	this.booking_address = ''
	this.repair_date = ''
	this.booking_key = ''
	this.submittedToShine = false
	
	this.ClientSecret = ''
	
    // Get the OAuth token on start-up
    //this.getAccessToken()


  }

  xWwwFormUrlEncoded (data: any): string {
    const components = []
    for (const property in data) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(data[property])
      components.push(encodedKey + '=' + encodedValue)
    }
    return components.join('&')
  }

  async getAccessToken () {
    const api = this

    const data = this.xWwwFormUrlEncoded({
      grant_type: 'password',
      username: this.username,
      password: this.password
    })

    const response = await fetch(
      this.urlBase + 'token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        body: data
      }
    )
    const json = await response.json()
    api.accessToken = json.access_token
  }
  
  async authenticate (username, password, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'adminuser/login?username=' + encodeURIComponent(username) + 
      '&password=' + encodeURIComponent(password)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])

      if (response.status != 200) {
		  OnFail();
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async addNewUser(first_name: string, last_name: string, email: string, membership_number: string) {
    const api = this
    const url = this.apiBase +
      'users/create?first_name=' + encodeURIComponent(first_name) +
      '&last_name=' + encodeURIComponent(last_name) +
      '&email=' + encodeURIComponent(email) +
      '&membership_number=' + encodeURIComponent(membership_number)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async updateUser(id: int, first_name: string, last_name: string, email: string, membership_number: string, onDone) {
    const api = this
    const url = this.apiBase +
      'users/update?id=' + encodeURIComponent(id) +
	  '&first_name=' + encodeURIComponent(first_name) +
      '&last_name=' + encodeURIComponent(last_name) +
      '&email=' + encodeURIComponent(email) +
      '&membership_number=' + encodeURIComponent(membership_number)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        //const json = await response.json()
		onDone()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async updateGroup(group_member_id: int, enrollment_id: int, new_group_id: int, onDone) {
    const api = this
    const url = this.apiBase +
      'groupmembers/changegroup?group_member_id=' + encodeURIComponent(group_member_id) +
	  '&enrollment_id=' + encodeURIComponent(enrollment_id) +
      '&new_group_id=' + encodeURIComponent(new_group_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        //const json = await response.json()
		onDone()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async ListUsers(onDone) {
    const api = this
    const url = this.apiBase +
      'users/list'

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async GetCurrentReviewPoint(enrollment_id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'reviewpoints/getcurrent?enrollment_id=' + encodeURIComponent(enrollment_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  OnFail();
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async GetCurrentReviewPointBasedOnEvent(event_id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'reviewpoints/getcurrentbasedOneventid?event_id=' + encodeURIComponent(event_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  onDone(null);
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async GetUserFromEnrollmentId(enrollment_id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'users/getfromenrollmentid?enrollment_id=' + encodeURIComponent(enrollment_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  OnFail();
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async GetGroupMemberFromEnrollmentId(enrollment_id, review_point_id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'groupmembers/getfromenrollmentid?enrollment_id=' + encodeURIComponent(enrollment_id) + 
      '&review_point_id=' + encodeURIComponent(review_point_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  OnFail();
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async ListScenarios(event_id, onDone) {
    const api = this
    const url = this.apiBase +
      'scenarios/list?event_id=' + encodeURIComponent(event_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async ListReviewPoints(scenario_point_id, onDone) {
    const api = this
    const url = this.apiBase +
      'reviewpoints/list?scenario_point_id=' + encodeURIComponent(scenario_point_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async ListScenarioPoints(scenario_id, onDone) {
    const api = this
    const url = this.apiBase +
      'scenariopoints/list?scenario_id=' + encodeURIComponent(scenario_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async ListReviewPointTypes(onDone) {
    const api = this
    const url = this.apiBase +
      'reviewpointtypes/list'

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async GetScenarioResults(attendee_id, scenario_id, onDone) {
    const api = this
    const url = this.apiBase +
      'attendees/scenarioresults?attendee_id=' + encodeURIComponent(attendee_id) + 
      '&scenario_id=' + encodeURIComponent(scenario_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async GetScenariosByAttendeeId(attendee_id, onDone) {
    const api = this
    const url = this.apiBase +
      'attendees/getscenariosbyattendeeid?attendee_id=' + encodeURIComponent(attendee_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async ListAttendees(event_id, onDone, ev) {
    const api = this
    const url = this.apiBase +
      'enrollments/list?event_id=' + encodeURIComponent(event_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json, ev);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async InviteAttendee(id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'enrollments/emailinvite?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
		  OnFail()
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
		  OnFail()
    }
  }

  async InviteAllAttendees(event_id, onDone, OnFail) {
    const api = this
    const url = this.apiBase +
      'enrollments/emailinviteallattendees?event_id=' + encodeURIComponent(event_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
		  OnFail()
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
		  OnFail()
    }
  }

  async UpdateScenarioName(id, newname) {
    const api = this
    const url = this.apiBase +
      'scenarios/updatename?id=' + encodeURIComponent(id) + 
      '&newname=' + encodeURIComponent(newname)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
      } else {
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async UpdateEventName(id, newname) {
    const api = this
    const url = this.apiBase +
      'events/updatename?id=' + encodeURIComponent(id) + 
      '&newname=' + encodeURIComponent(newname)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
      } else {
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async UpdateScenarioPointName(id, newname) {
    const api = this
    const url = this.apiBase +
      'scenariopoints/updatename?id=' + encodeURIComponent(id) + 
      '&newname=' + encodeURIComponent(newname)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
      } else {
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async UpdateReviewPointName(id, newname) {
    const api = this
    const url = this.apiBase +
      'reviewpoints/updatename?id=' + encodeURIComponent(id) + 
      '&newname=' + encodeURIComponent(newname)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
      } else {
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async UpdateScenarioGroupName(id, newname) {
    const api = this
    const url = this.apiBase +
      'scenariogroups/updatename?id=' + encodeURIComponent(id) + 
      '&newname=' + encodeURIComponent(newname)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
      } else {
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async UpdateGroupName(id, newname) {
    const api = this
    const url = this.apiBase +
      'groups/updatename?id=' + encodeURIComponent(id) + 
      '&newname=' + encodeURIComponent(newname)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
      } else {
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async UpdateEnrollmentAllocationNumber(id, newnumber) {
    const api = this
    const url = this.apiBase +
      'enrollments/updateallocationnumber?id=' + encodeURIComponent(id) + 
      '&newnumber=' + encodeURIComponent(newnumber)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
      if (response.status != 200) {
      } else {
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async ListReviewPointGroups(review_point_id, onDone) {
    const api = this
    const url = this.apiBase +
      'groups/list?review_point_id=' + encodeURIComponent(review_point_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async DownloadData(onDone) {
    const api = this
    const url = this.apiBase +
      'groupmemberresponse/downloaddata'

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const data = await response.text()
		onDone(data);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async ListScenarioGroups(scenario_id, onDone) {
    const api = this
    const url = this.apiBase +
      'scenariogroups/list?scenario_id=' + encodeURIComponent(scenario_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async AddNewScenario(event_id, name, template, onDone) {
    const api = this
    const url = this.apiBase +
      'scenarios/create?event_id=' + encodeURIComponent(event_id) + 
      '&name=' + encodeURIComponent(name) +
      '&template_id=' + encodeURIComponent(template)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async deleteScenario(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'scenarios/Delete?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		  onDone()
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async AddNewReviewPoint(scenario_point_id, name, review_self, review_point_type_id, onDone) {
    const api = this
	var str_review_self = 'false'
	if (review_self)
	{
		str_review_self = 'true'
	}
    const url = this.apiBase +
      'reviewpoints/create?scenario_point_id=' + encodeURIComponent(scenario_point_id) + 
      '&name=' + encodeURIComponent(name) +
      '&review_self=' + encodeURIComponent(str_review_self) +
      '&review_point_type_id=' + encodeURIComponent(review_point_type_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async deleteReviewPoint(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'reviewpoints/Delete?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		  onDone()
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async AddNewScenarioPoint(scenario_id, name, onDone) {
    const api = this
    const url = this.apiBase +
      'scenariopoints/create?scenario_id=' + encodeURIComponent(scenario_id) + 
      '&name=' + encodeURIComponent(name)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async deleteScenarioPoint(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'scenariopoints/Delete?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		  onDone()
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async getScenarioPoint(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'scenariopoints/get?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async ListAllEventReviewPoints(event_id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'reviewpoints/listallevent?event_id=' + encodeURIComponent(event_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		onDone(null);
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		onDone(null);
    }
  }

  async getScenario(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'scenarios/get?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async showScenarioReport(id: string, show: string, onDone) {
    const api = this
    const url = this.apiBase +
      'scenarios/showreport?id=' + encodeURIComponent(id) + 
      '&show=' + encodeURIComponent(show)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async toggleReviewPointLive(id: string, live: string, onDone) {
    const api = this
    const url = this.apiBase +
      'reviewpoints/togglelive?id=' + encodeURIComponent(id) + 
      '&live=' + encodeURIComponent(live)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		onDone();
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async deleteUser(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'users/Delete?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		  onDone()
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async addNewEvent(name: string, event_date: string) {
    const api = this
    const url = this.apiBase +
      'events/create?name=' + encodeURIComponent(name) +
      '&event_date=' + encodeURIComponent(event_date)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async addAttendee(user_id: string, event_id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'enrollments/create?user_id=' + encodeURIComponent(user_id) +
      '&event_id=' + encodeURIComponent(event_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        //const json = await response.json()
		onDone()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async getAttendee(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'enrollments/get?id=' + encodeURIComponent(id)
	  
    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		onDone(json)
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async ListEvents(onDone) {
    const api = this
    const url = this.apiBase +
      'events/list'

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		console.log(json);
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async deleteEvent(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'events/Delete?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		  onDone()
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }
  
  async removeAttendee(id: string, onDone) {
    const api = this
    const url = this.apiBase +
      'enrollments/Delete?id=' + encodeURIComponent(id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		  onDone()
        //const json = await response.json()
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async listOpponents(group_member_id, onDone) {
    const api = this
    const url = this.apiBase +
      'groupmembers/listopponents?group_member_id=' + encodeURIComponent(group_member_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		console.log(json);
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async generateReport(group_member_id, onDone) {
    const api = this
    const url = this.apiBase +
      'groupmembers/generatereport?group_member_id=' + encodeURIComponent(group_member_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
        const json = await response.json()
		console.log(json);
		onDone(json);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async createGroupMemberResponse(group_member_id, opposing_group_member_id, response_id, onDone) {
    const api = this
    const url = this.apiBase +
      'groupmemberresponse/create?group_member_id=' + encodeURIComponent(group_member_id) +
      '&opposing_group_member_id=' + encodeURIComponent(opposing_group_member_id) +
      '&response_id=' + encodeURIComponent(response_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  console.log('apifail')
      } else {
		onDone(response_id);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  async getGroupMemberResponse(group_member_id, opposing_group_member_id, onDone) {
    const api = this
    const url = this.apiBase +
      'groupmemberresponse/list?group_member_id=' + encodeURIComponent(group_member_id) +
      '&opposing_group_member_id=' + encodeURIComponent(opposing_group_member_id)

    try {
      const response = await Promise.race([
        fetch(
          url, {
            method: 'GET',
            headers: this.accessHeaders()
          }
        ),
        this.timeoutPromise(this.standardTimeout)
      ])
		  
      if (response.status != 200) {
		  onDone(group_member_id, '');
      } else {
		const json = await response.json()
		console.log(json);
		onDone(group_member_id, json.response_id);
      }
    } catch (e) {
		  console.log(e)
    }
  }

  timeoutPromise (timeout: number): Promise<Response> {
    return new Promise<Response>((resolve, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
  }

  accessHeaders () {
    return {
      Authorization: 'Bearer ' + this.accessToken
    }
  }

}
