import React, { useState, createRef } from 'react'
import { StyleSheet, View, ScrollView, Text, Picker } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ContentEditable from 'react-contenteditable'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Table from '../components/Table'
import Api from '../components/Api'

interface ViewReviewPointProps {
  navigation: any
  onContinue: Function
  selected_review_point: any
  groups: any
  gotopage: Function
  execute_review_point: Function
}


export default function ViewReviewPoint ({ navigation, onContinue, selected_review_point, groups, gotopage, execute_review_point }: ViewReviewPointProps) {
	const [local_groups, setUpdate] = useState(groups)

   const columns = React.useMemo(
     () => [
       {
			Header: "First name",
			accessor: "first_name",
			filterable: true
		},
		{
			Header: "Last name",
			accessor: "last_name",
			filterable: true
		},
       {
			Header: "Responses",
			accessor: "available_responses_count",
			filterable: true,
			Cell: ( props ) => (
				  <div>
					{props.row.original.responded_count} / {props.row.original.available_responses_count}
				  </div>
				)
		},
       {
			Header: "Change group",
			Cell: ( props ) => (
				  <div>
					<Picker
						selectedValue={"-1"}
						style={{ height: 30, width: 200 }}
						onValueChange={(itemValue, itemIndex) => updateGroup(props.row.original.enrollment_id, props.row.original.group_member_id, itemValue)}>
							<Picker.Item label="--Select group--" value="-1" />
						  {local_groups.map((c, i) => (
								<Picker.Item label={c.name} value={c.id} />
						  ))}
					  </Picker>				  
				  </div>
				)
		},
		{
			Header: "",
			accessor: "membership_number",
Cell: ( props ) => (
	  <div>
		<a href={"/?id=" + props.row.original.enrollment_id} target="_blank">Launch</a>
		</div>
      )
	  },
     ],
     []
   )

	function handleChange(group_id, newname) {
		new Api().UpdateGroupName(group_id, newname)
  }
  
  	function doExecuteReviewPoint(group_member) {
		execute_review_point(group_member)
	}
	
	const updateGroup = (enrollment_id, group_member_id, group_id) => {
		console.log(group_member_id)
	 new Api().updateGroup(group_member_id, enrollment_id, group_id, onGroupUpdateDone)
	}
	
	const onGroupUpdateDone = () => {
		new Api().ListReviewPointGroups(selected_review_point.id, onListGroupDone)
	}
	
	const onListGroupDone = (data) => {
		groups = data;
		setUpdate(data)
	}

	function handleChangeName(review_point_id, newname) {
		selected_review_point.name = newname;
		new Api().UpdateReviewPointName(review_point_id, newname)
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Review Type: 
				<ContentEditable
							innerRef={selected_review_point}
						  html={selected_review_point.name} // innerHTML of the editable div
						  disabled={false}       // use true to disable editing
						  onChange={(val) => handleChangeName(selected_review_point.id, val.target.value)} // handle innerHTML change
						  tagName='article' // Use a custom HTML tag (uses a div by default)
						/>
			</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listevents')}>List Programmes</a> -> <a href='#' onClick={() => gotopage('viewevent')}>View Programme</a> -> <a href='#' onClick={() => gotopage('viewscenario')}>View Scenario</a> -> <a href='#' onClick={() => gotopage('viewscenariopoint')}>View Scenario Point</a> -> View Review Type</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<Text style={styles.sectionHeader}>Groups</Text>
				<VerticalSpace height={20} />
				<ScrollView
				  style={styles.ScrollViewDriver}>
				  {local_groups.map((c, i) => (
					<View style={styles.table}>
						<ContentEditable
							style={{marginBottom: 10, marginTop: 10, padding: 5, width: 200, fontWeight: 'bold'}}
							innerRef={c}
						  html={c.name} // innerHTML of the editable div
						  disabled={false}       // use true to disable editing
						  onChange={(val) => handleChange(c.id, val.target.value)} // handle innerHTML change
						  tagName='article' // Use a custom HTML tag (uses a div by default)
						/>
						<Table data={c.group_members} columns={columns} hideSearch={true} />
					</View>
				  ))}
				</ScrollView>
				<VerticalSpace height={40} />
				<DefaultButton onPress={onContinue} text="Return"/>
				<VerticalSpace height={20} />
			</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: '100%',
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },  
})
