import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView, Text, TouchableWithoutFeedback, Platform, FlatList, TouchableOpacity, Dimensions } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import { FlatGrid } from 'react-native-super-grid';
import Api from '../components/Api'
import '@expo/match-media'
// Unleash the demo :D
import { useMediaQuery } from "react-responsive";
import { width, height, totalSize } from 'react-native-dimension';
import AwesomeAlert from 'react-native-awesome-alerts';



import greentick from '../assets/greentick.png'
import pallette from '../assets/palette-grid.png'
import quad1 from '../assets/quad1.png'
import quad2 from '../assets/quad2.png'
import quad3 from '../assets/quad3.png'
import quad4 from '../assets/quad4.png'


interface ViewUserScenarioProps {
  navigation: any
  onContinue: Function
  group_member: any
  opponents: any
  reviewee: any
  ReviewOpponent: Function
  ViewMySummary: Function
  response_id: any
  SetResponseId: Function
  reviewed_self: any
  SetReviewedSelf: Function
  selected_review_point : Any
}



export default function ViewUserScenario ({ navigation, onContinue, group_member, opponents, reviewee, ReviewOpponent, ViewMySummary, response_id, SetResponseId, reviewed_self, SetReviewedSelf, selected_review_point }: ViewUserScenarioProps) {
	const [selectedId, setSelectedId] = useState(response_id)
	const [showAdvHigh, setShowAdvHigh] = useState(true);
	const [showAdvLow, setShowAdvLow] = useState(true);
	const [showCollHigh, setShowCollHigh] = useState(true);
	const [showCollLow, setShowCollLow] = useState(true);
	const [local_reviewed_self, setReviewed_self] = useState(reviewed_self);
	const [sb_user, SetSBUser] = useState(null)
	const [review_point, SetReviewPoint] = useState(null)
	const [scenario_point, SetScenarioPoint] = useState(null)
	const [scenario, SetScenario] = useState(null)
	const [buttonWidth, SetButtonWidth] = useState(132)
	const [showAlert, SetShowAlert] = useState(false)
	const [showThanks, SetShowThanks] = useState(false)

	const showAlertBox = () => {
      SetShowAlert(true)
	};

	const hideAlert = () => {
	  SetShowAlert(false)
	};

	const showThanksBox = () => {
      SetShowThanks(true)
	};

	const hideThanks = () => {
	  SetShowThanks(false)
	};

	const isTabletOrMobileDevice = useMediaQuery({    
		maxWidth: 750,
		// alternatively...
		query: "(max-width: 750px)"  
	  });

  const doReviewOpponent = (opponent) => {
	  ReviewOpponent(opponent)
  }
  
  Dimensions.addEventListener('change', () => {
	  console.log('change')
		if (width(100) <= 600)
		{
			SetButtonWidth(width(100) / 8)
		}
		else if (width(100) <= 900)
		{
			SetButtonWidth(width(100) / 7)
		}
    });
  
  const items = [{name: 'Angry', id: 1},
 {name: 'Annoyed', id: 2},
 {name: 'Fearful', id: 3},
 {name: 'Awed', id: 4},
 {name: 'Triumphant', id: 5},
 {name: 'Elated', id: 6},
 {name: 'Disgusted', id: 7},
 {name: 'Contemptuous', id: 8},
 {name: 'Surprised', id: 9},
 {name: 'Keen', id: 10},
 {name: 'Hopeful', id: 11},
 {name: 'Happy', id: 12},
 {name: 'Irritated', id: 13},
 {name: 'Pained', id: 14},
 {name: 'Confused', id: 15},
 {name: 'Interested', id: 16},
 {name: 'Amused', id: 17},
 {name: 'Optimistic', id: 18},
 {name: 'Deflated', id: 19},
 {name: 'Disappointed', id: 20},
 {name: 'Doubtful', id: 21},
 {name: 'Focused', id: 22},
 {name: 'Contented', id: 23},
 {name: 'Pleased', id: 24},
 {name: 'Sad', id: 25},
 {name: 'Despondent', id: 26},
 {name: 'Disheartened', id: 27},
 {name: 'Thoughtful', id: 28},
 {name: 'Satisfied', id: 29},
 {name: 'Relaxed', id: 30},
 {name: 'Pessimistic', id: 31},
 {name: 'Apathetic', id: 32},
 {name: 'Complacent', id: 33},
 {name: 'Tranquil', id: 34},
 {name: 'Composed', id: 35},
 {name: 'Calm', id: 36}];
 
	  
	useEffect(() => {
		if (reviewee.group_member_id == null)
		{
			if (selected_review_point.review_self == 'true') {
				doReviewOpponent(group_member)
			}
			else
			{
				doReviewOpponent(opponents[0])
			}
		}
		if (width(100) <= 600)
		{
			SetButtonWidth(width(100) / 8)
		}
		else if (width(100) <= 900)
		{
			SetButtonWidth(width(100) / 7)
		}
		const query = new URLSearchParams(location.search);
		new Api().GetUserFromEnrollmentId(query.get('id'), onGetUserFromEnrollmentIdDone, onGetUserFromEnrollmentIdFail)
		new Api().GetCurrentReviewPoint(query.get('id'), onGetCurrentReviewPointDone, onGetCurrentReviewPointFail)
	}, [])
	
	const onGetCurrentReviewPointDone = (review_point) => {
		new Api().getScenarioPoint(review_point.scenario_point_id, onGetScenarioPointDone)
	}

	const onGetCurrentReviewPointFail = () => {
	}

	const onGetScenarioPointDone = (scenario_point) => {
		SetScenarioPoint(scenario_point)
		new Api().getScenario(scenario_point.scenario_id, onGetScenarioDone)
	}

	const onGetScenarioDone = (scenario) => {
		SetScenario(scenario)
	}

	
	const onGetUserFromEnrollmentIdDone = (sb_user) => {
		SetSBUser(sb_user)
		if (selectedId == 1 || selectedId == 2 || selectedId == 3 || selectedId == 7 || selectedId == 8 || selectedId == 9 || selectedId == 13 || selectedId == 14 || selectedId == 15)
		{
			setShowAdvHigh(false);
		}
		else if (selectedId == 4 || selectedId == 5 || selectedId == 6 || selectedId == 10 || selectedId == 11 || selectedId == 12 || selectedId == 16 || selectedId == 17 || selectedId == 18)
		{
			setShowCollHigh(false);
		}
		else if (selectedId == 19 || selectedId == 20 || selectedId == 21 || selectedId == 25 || selectedId == 26 || selectedId == 27 || selectedId == 31 || selectedId == 32 || selectedId == 33)
		{
			setShowAdvLow(false);
		}
		else if (selectedId == 22 || selectedId == 23 || selectedId == 24 || selectedId == 28 || selectedId == 29 || selectedId == 30 || selectedId == 34 || selectedId == 35 || selectedId == 36)
		{
			setShowCollLow(false);
		}
		
	}

	const onGetUserFromEnrollmentIdFail = () => {

	}
	
	const onContinueLocal = () => {
		//SetReviewedSelf(local_reviewed_self)
		if (opponents.filter(item => item.my_response_about_them === null).length > 0)
		{
			showAlertBox()
		}
		else
		{
			if (selected_review_point.review_self == 'true' && local_reviewed_self == '')
			{
				showAlertBox()
			}
			else
			{
				showThanksBox()
			}
		}
	}


	const handleSelection = (id) => {
		console.log('test1')
		new Api().createGroupMemberResponse(group_member.id, reviewee.group_member_id, id, createGroupMemberResponseDone)
		setSelectedId(id)
		opponents.forEach(d => {if(d.group_member_id == reviewee.group_member_id){d.my_response_about_them = true}} )
	}
	
	const createGroupMemberResponseDone = (id) => {
		if (group_member.id == reviewee.id)
		{
			setReviewed_self(true)
			SetReviewedSelf(true)
			SetResponseId(id)
		}
	}
	
	const renderGridItem = (props) => (
  <TouchableWithoutFeedback onPress={() => handleSelection(props.id)} >
	<View style={width(100) < 900 ? props.id === selectedId ? styles.mobileselecteditem : styles.mobileitem : props.id === selectedId ? styles.selecteditem : styles.item}  >
	  <View style={styles.flex} />
	  <Text style={[styles.name, width(100) < 900 ? props.id === selectedId ? styles.mobileselecteditem : styles.mobileitem : props.id === selectedId ? styles.selecteditem : styles.item, width(100) < 900 ? styles.mobileItem : null]} >
		{props.name}
	  </Text>
	</View>
	</TouchableWithoutFeedback>
	);
	
	const toggleShowAdvHigh = () => {
		setShowAdvHigh(false);
		setShowAdvLow(true);
		setShowCollHigh(true);
		setShowCollLow(true);
	}
	
	const toggleShowAdvLow = () => {
		setShowAdvHigh(true);
		setShowAdvLow(false);
		setShowCollHigh(true);
		setShowCollLow(true);
	}
	
	const toggleShowCollHigh = () => {
		setShowAdvHigh(true);
		setShowAdvLow(true);
		setShowCollHigh(false);
		setShowCollLow(true);
	}
	
	const toggleShowCollLow = () => {
		setShowAdvHigh(true);
		setShowAdvLow(true);
		setShowCollHigh(true);
		setShowCollLow(false);
	}

	const toggleReset = () => {
		setShowAdvHigh(true);
		setShowAdvLow(true);
		setShowCollHigh(true);
		setShowCollLow(true);
	}
	
	const selectNotPresent = () => {
		toggleReset();
		handleSelection(-1);
	}
	
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar navigation={navigation} name={sb_user == null ? null: sb_user.first_name + " " + sb_user.last_name}></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Para>Welcome to E.A.T. – our Emotion Awareness Tool<br />E.A.T. is an emotional intelligence tool designed to measure how good we are at recognising our own emotions as well as those on the other side of the table in our negotiations.</Para>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<Para>This scenario is: {scenario == null ? null : scenario.name}</Para>
				<Para>This review point is: {scenario_point == null ? null : scenario_point.name}</Para>
				<Para>Click to select participant and then emotion, continue until all are completed and have green ticks:</Para>
				<ScrollView style={styles.ScrollViewDriver}>
					{ sb_user == null ? null : selected_review_point.review_self == 'true' ? <View><Text style={[group_member.id == reviewee.id ? styles.tableData1Selected : styles.tableData1S, styles.floating,  local_reviewed_self == '' ?  null : styles.tableData1Completed]} onPress={() => doReviewOpponent(group_member)}>{sb_user.first_name} {sb_user.last_name} (me)</Text></View> : null}
							{isTabletOrMobileDevice ? 
					<FlatList
					  data={opponents}
					  style={styles.table}
					  numColumns={1}
					  key={'_'}
					  keyExtractor={item => "_" + item.id}
					  renderItem={({item: opponent}) => <View><Text style={[opponent.id == reviewee.id ? styles.tableData1Selected : styles.tableData1S,  opponent.my_response_about_them == null ?  null : styles.tableData1Completed]} onPress={() => doReviewOpponent(opponent)}>{opponent.first_name} {opponent.last_name}</Text></View>}
					/>
					:
					<FlatList
					  data={opponents}
					  style={styles.table}
					  key={'#'}
					  keyExtractor={item => "#" + item.id}
					  numColumns={4}
					  renderItem={({item: opponent}) => <View><Text style={[opponent.id == reviewee.id ? styles.tableData1Selected : styles.tableData1S,  opponent.my_response_about_them == null ?  null : styles.tableData1Completed]} onPress={() => doReviewOpponent(opponent)}>{opponent.first_name} {opponent.last_name}</Text></View>}
					/>
					}
				</ScrollView>
				<Para>Click to select which quadrant best represents their/your emotion</Para>
				<Para><Text style={[selectedId == -1 ? styles.tableData2Selected : styles.tableData2S, styles.floating]} onPress={() => selectNotPresent()}></Text><Text>Click here if participant not present.</Text></Para>
			</View>
		</View>
		<View style={width(100) < 900 ? null : [styles.innerContainer, styles.container]}>
			<View horizontal={true} style={styles.flatGrid}>
				<View style={width(100) < 900 ? styles.mobilegrid : styles.grid}>
					<FlatGrid
					  itemDimension={buttonWidth}
					  fixed={true}
					  showsHorizontalScrollIndicator={false}
					  showsVerticalScrollIndicator={false}
					  extraData={
						selectedId     // for single item
					  }
					  style={styles.flatGrid}
					  data={items}
					  renderItem={({ item }) => renderGridItem(item)}
					/>
					{showAdvHigh == true ? <TouchableOpacity style={width(100) < 900 ? styles.mobileoverlayAdvHigh : styles.overlayAdvHigh} onPress={() => toggleShowAdvHigh()}>
						<Text style={width(100) < 900 ? styles.mobileoverlayText : styles.overlayText}>Adversarial<br />High intensity</Text>
					</TouchableOpacity> : null }
					{showAdvLow == true ? <TouchableOpacity style={width(100) < 900 ? styles.mobileoverlayAdvLow : styles.overlayAdvLow} onPress={() => toggleShowAdvLow()}>
						<Text style={width(100) < 900 ? styles.mobileoverlayText : styles.overlayText}>Adversarial<br />Low intensity</Text>
					</TouchableOpacity> : null }
					{showCollHigh == true ? <TouchableOpacity style={width(100) < 900 ? styles.mobileoverlayCollHigh : styles.overlayCollHigh} onPress={() => toggleShowCollHigh()} >
						<Text style={width(100) < 900 ? styles.mobileoverlayText : styles.overlayText}>Collaborative<br />High intensity</Text>
					</TouchableOpacity> : null }
					{showCollLow == true ? <TouchableOpacity style={width(100) < 900 ? styles.mobileoverlayCollLow : styles.overlayCollLow} onPress={() => toggleShowCollLow()} >
						<Text style={width(100) < 900 ? styles.mobileoverlayText : styles.overlayText}>Collaborative<br />Low intensity</Text>
					</TouchableOpacity> : null }
				</View>
			</View>
		</View>
		<View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
        <VerticalSpace height={20} />
        <DefaultButton onPress={onContinueLocal} text="Continue"/>
        <VerticalSpace height={20} />
			</View>
	<AwesomeAlert
          show={showAlert}
          showProgress={false}
          title="Alert"
          message="You still have participants to choose for, are you sure you want to continue?"
		  messageColor="000000"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          cancelText="No"
          confirmText="Yes"
          cancelButtonColor="#FF0000"
          confirmButtonColor={Colors.green}
          onCancelPressed={() => {
            hideAlert();
          }}
          onConfirmPressed={() => {
            onContinue();
          }}
        />
	<AwesomeAlert
          show={showThanks}
          showProgress={false}
          title="Thanks"
          message="Thanks, you have have successfully completed this task"
		  messageColor="000000"
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={true}
          cancelText="No"
          confirmText="OK"
          cancelButtonColor="#FF0000"
          confirmButtonColor={Colors.green}
          onConfirmPressed={() => {
            onContinue();
          }}
        />
		  </View>
		  <FooterBar />
    </KeyboardAwareScrollView>
	
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  mobileItem: {
	  fontSize: 7,
  },
  name: {
	  paddingTop: '10%',

  },
  sectionHeader: {
	  fontWeight: 'bold'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  namestable: {
	  width: 900,
	  justifyContent: 'space-evenly'
  },
  table: {
	  width: '100%'
  },
  grid: {
	  backgroundImage: 'url(' + pallette + ')',
	  backgroundSize: '100% 100%',
	  backgroundRepeat: 'no-repeat',
	  backgroundHeight: 600,
	  width: '100%',
	  height: 600,
	  paddingTop: 5,
  },
  mobilegrid: {
	  backgroundImage: 'url(' + pallette + ')',
	  backgroundSize: '100% 100%',
	  backgroundHeight: 238,
	  width: '100%',
	  height: 236,
	  paddingTop: 5,
  },
  flatGrid: {

  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: '#000',
	  display: 'table-cell',
	  width: 200,
	  padding: 5,
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  margin: 10,
	  backgroundColor: Colors.white,
	  borderRadius: 10,
  },
  tableData1Selected: {
	  display: 'table-cell',
	  width: 200,
	  padding: 5,
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  margin: 10,
	  backgroundColor: Colors.black,
	  color: Colors.white,
	  borderRadius: 10,
  },
  tableData2S: {
	  color: '#000',
	  display: 'table-cell',
	  width: 10,
	  height: 10,
	  padding: 5,
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  margin: 5,
	  backgroundColor: Colors.white,
  },
  tableData2Selected: {
	  display: 'table-cell',
	  width: 10,
	  height: 10,
	  padding: 5,
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  margin: 5,
	  backgroundColor: Colors.green,
	  color: Colors.white,
  },
  tableData1Completed: {
	  backgroundImage: 'url(' + greentick + ')',
	  backgroundRepeat: 'no-repeat',
	  backgroundPositionX: 173,
	  backgroundPositionY: 3,
	  backgroundSize: 20
  },
  
 
  notselected: {
	  backgroundColor: 'darkgray'
  },
  mobileitem: {
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  cursor: 'pointer',
	  height: 26,
  },
  mobileselecteditem: {
	  backgroundColor: Colors.black,
	  color: Colors.white,
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  height: 26,
  },
  item: {
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  cursor: 'pointer',
	  height: 86,
  },
  selecteditem: {
	  backgroundColor: Colors.black,
	  color: Colors.white,
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  height: 86,
  },
  greenStripe: {
	  paddingTop: 20,
	  paddingBottom: 20,
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  floating: {
	  float: 'left',
  },
  overlayAdvHigh: {
	  backgroundImage: 'url(' + quad1 + ')',
      backgroundSize: '100% 100%',
	  position: 'absolute',
	  top: 0,
	  left: 0,
	  width: '50%',
	  height: 300,
  },
  overlayAdvLow: {
	  backgroundImage: 'url(' + quad3 + ')',
      backgroundSize: '100% 100%',
	  position: 'absolute',
	  top: 300,
	  left: 0,
	  width: '50%',
	  height: 300,
  },
  overlayCollHigh: {
	  backgroundImage: 'url(' + quad2 + ')',
      backgroundSize: '100% 100%',
	  position: 'absolute',
	  top: 0,
	  left: '50%',
	  width: '50%',
	  height: 300,
  },
  overlayCollLow: {
	  backgroundImage: 'url(' + quad4 + ')',
      backgroundSize: '100% 100%',
	  backgroundRepeat: 'no-repeat',
	  position: 'absolute',
	  top: 300,
	  left: '50%',
	  width: '50%',
	  height: 300,
  },
  overlayText: {
	  paddingTop: 235,
	  fontWeight: 'bold',
	  fontSize: 20,	  
	  textAlign: 'Center',
	  height: 300,
	  borderStyle: 'dotted',
	  borderRadius: 1,
	  borderWidth: 1,
  },
  mobileoverlayAdvHigh: {
	  backgroundImage: 'url(' + quad1 + ')',
      backgroundSize: '100% 100%',
	  position: 'absolute',
	  top: 0,
	  left: 0,
	  width: '50%',
	  height: 118,
  },
  mobileoverlayAdvLow: {
	  backgroundImage: 'url(' + quad3 + ')',
      backgroundSize: '100% 100%',
	  position: 'absolute',
	  top: 118,
	  left: 0,
	  width: '50%',
	  height: 118,
  },
  mobileoverlayCollHigh: {
	  backgroundImage: 'url(' + quad2 + ')',
      backgroundSize: '100% 100%',
	  position: 'absolute',
	  top: 0,
	  left: '50%',
	  width: '50%',
	  height: 118,
  },
  mobileoverlayCollLow: {
	  backgroundImage: 'url(' + quad4 + ')',
      backgroundSize: '100% 100%',
	  backgroundRepeat: 'no-repeat',
	  position: 'absolute',
	  top: 118,
	  left: '50%',
	  width: '50%',
	  height: 118,
  },
  mobileoverlayText: {
	  paddingTop: 80,
	  fontWeight: 'bold',
	  fontSize: 12,	  
	  textAlign: 'Center',
	  height: 118,
	  borderStyle: 'dotted',
	  borderRadius: 1,
	  borderWidth: 1,
  },
  my_response: {
	  width: 200,
	  marginTop: -10,
	  textAlign: 'center',
  }

})
