import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, Text, Picker } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import ContentEditable from 'react-contenteditable'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Table from '../components/Table'
import Api from '../components/Api'
import { confirmAlert } from 'react-confirm-alert'; // Import

interface ViewScenarioProps {
  navigation: any
  onContinue: Function
  selected_scenario: any
  scenario_points: any
  gotopage: Function
  removeScenarioPoint: Function
  viewScenarioPoint: Function
  viewPDFReport: Function
}


export default function ViewScenario ({ navigation, onContinue, selected_scenario, scenario_points, gotopage, removeScenarioPoint, viewScenarioPoint, groups, viewPDFReport }: ViewScenarioProps) {
	const [local_groups, setUpdate] = useState(groups)
   const attendee_columns = React.useMemo(
     () => [
       {
			Header: "First name",
			accessor: "enrolledUser.first_name",
			filterable: true
		},
		{
			Header: "Last name",
			accessor: "enrolledUser.last_name",
			filterable: true
		},
		{
			Header: "Email",
			accessor: "enrolledUser.email",
			filterable: true
		},
		{
			Header: "Membership number",
			accessor: "enrolledUser.membership_number",
			filterable: true
		},
		{
			Header: "",
			accessor: "id",
Cell: ( props ) => (
	  <div>
		<a href="#" onClick={e => doViewPDFReport(props.row.original.enrolledUser)}>Report</a>
		</div>
      )
	  },
     ],
     []
   )

   const scenario_points_columns = React.useMemo(
     () => [
       {
			Header: "Name",
			accessor: "name",
			filterable: true,
			Cell: ( props ) => (
				  <div>
					<a href="#" onClick={e => doViewScenarioPoint(props.row.original)}>{props.row.original.name}</a>
					</div>
				  )
		},
		{
			Header: "",
			accessor: "id",
Cell: ( props ) => (
	  <div>
		<a href="#" onClick={e => doRemoveScenarioPoint(props.row.original.id)}>Delete</a>
		</div>
      )
	  },
     ],
     []
   )


  const doRemoveScenarioPoint = (id) => {
		confirmAlert({
		  title: 'Confirm delete',
		  message: 'Are you sure you want to do this.',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => removeScenarioPoint(id)
			},
			{
			  label: 'No'
			}
		  ]
		});
  }
  
  	const doViewScenarioPoint = (id) => {
		viewScenarioPoint(id)
	}
	
  const doViewPDFReport = (attendee) => {
	  viewPDFReport(attendee)
  }

	function handleChangeName(scenario_id, newname) {
		selected_scenario.name = newname;
		new Api().UpdateScenarioName(scenario_id, newname)
  }

	function handleChangeGroupName(group_id, newname) {
		new Api().UpdateScenarioGroupName(group_id, newname)
  }

	const updateGroup = (enrollment_id, group_member_id, group_id) => {
	 new Api().updateGroup(group_member_id, enrollment_id, group_id, onGroupUpdateDone)
	}
	
	const onGroupUpdateDone = () => {
		new Api().ListScenarioGroups(selected_scenario.id, onListScenarioGroupsDone)
	}
	
	const onListScenarioGroupsDone = (data) => {
		groups = data;
		setUpdate(data)
	}

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Scenario: 
				<ContentEditable
							innerRef={selected_scenario}
						  html={selected_scenario.name} // innerHTML of the editable div
						  disabled={false}       // use true to disable editing
						  onChange={(val) => handleChangeName(selected_scenario.id, val.target.value)} // handle innerHTML change
						  tagName='article' // Use a custom HTML tag (uses a div by default)
						/>
			</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listevents')}>List Programmes</a> -> <a href='#' onClick={() => gotopage('viewevent')}>View Programme</a> -> View Scenario</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('addscenariopoint')} text="Add Scenario Point"/>
				<VerticalSpace height={20} />
				<Text style={styles.sectionHeader}>Scenario Points</Text>
				<VerticalSpace height={20} />
				<Table data={scenario_points} columns={scenario_points_columns} hideSearch={true} />
				<VerticalSpace height={40} />
				<DefaultButton onPress={onContinue} text="Return"/>
				<VerticalSpace height={20} />
			</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },  
})
