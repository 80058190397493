import React, { useState } from 'react'
import { StyleSheet, View, Picker } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'

interface NewScenarioPointProps {
  navigation: any
  onContinue: Function
  gotopage: Function
}


export default function NewScenarioPoint ({ navigation, onContinue, gotopage }: NewScenarioPointProps) {
  const [name, setName] = useState('')

  const canContinue = () => (name !== '')
  
  const updateName = (text: string) => {
	  setName(text)
  }

  function doContinue()
  {
	  onContinue(name)
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Add New Scenario point</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listevents')}>List Programmes</a> -> <a href='#' onClick={() => gotopage('viewevent')}>View Programme</a> -> <a href='#' onClick={() => gotopage('viewscenario')}>View Scenario</a> -> Add Scenario Point</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
		<View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
			<VerticalSpace height={20} />
			<LabeledTextField placeholder="Required" label="Name" onChange={updateName} value={name}/>
			<VerticalSpace height={40} />
			<ConditionalButton onPress={doContinue} condition={canContinue} text="Continue"/>
			<VerticalSpace height={20} />
			<DefaultButton onPress={() => gotopage('viewscenario')} text={'cancel'} />
			<VerticalSpace height={20} />
		  </View>
      </View>
    </KeyboardAwareScrollView>
  )
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },  
})
