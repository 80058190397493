import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import Colors from '../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Styles from '../constants/Styles'


type FooterBarProps = {
  gotopage: Function
}

const FooterBar = ({ gotopage }: FooterBarProps) => {
  return (
    <View style={styles.navigationBar}>
		<View style={[styles.innerContainer, styles.container]}>
			<Text style={{color: 'white'}}>&copy; {new Date().getFullYear()} by Savage Macbeth.</Text>
		</View>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    // backgroundColor: Colors.primaryPurple, // AP
    backgroundColor: Colors.black, // Shine
    color: Colors.white, // Shine
    paddingBottom: 40,
    paddingTop: 40,
	zIndex: -1,
  },
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },

})


export default FooterBar
