import React, { useState, useRef, useEffect } from 'react'
import { StyleSheet, View, ScrollView, Text, TouchableWithoutFeedback, Platform, Alert, Button } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import { FlatGrid } from 'react-native-super-grid';
import Api from '../components/Api'
import { CSVLink, CSVDownload } from 'react-csv'


interface ViewUserScenarioReviewProps {
  navigation: any
  onContinue: Function
  group_member: any
  opponents: any
  reviewee: any
  ReviewOpponent: Function
  PrintReport: Function
  response_id: any
}


export default function ViewUserScenarioReview ({ navigation, onContinue, group_member, opponents, reviewee, ReviewOpponent, PrintReport, response_id }: ViewUserScenarioReviewProps) {
	const [selectedId, setSelectedId] = useState(response_id)
	const [data, setData] = useState(null)

  const doReviewOpponent = (opponent) => {
	  ReviewOpponent(opponent)
	  fetchData()
  }
  
  const items = [];
	  items.push({name: 'Angry', id: 1});
	  items.push({name: 'Annoyed', id: 2});
	  items.push({name: 'Fearful', id: 3});
	  items.push({name: 'Awed', id: 4});
	  items.push({name: 'Triumphant', id: 5});
	  items.push({name: 'Elated', id: 6});
	  items.push({name: 'Disgusted', id: 7});
	  items.push({name: 'Contemptuous', id: 8});
	  items.push({name: 'Surprised', id: 9});
	  items.push({name: 'Keen', id: 10});
	  items.push({name: 'Hopeful', id: 11});
	  items.push({name: 'Happy', id: 12});
	  items.push({name: 'Irritated', id: 13});
	  items.push({name: 'Pained', id: 14});
	  items.push({name: 'Confused', id: 15});
	  items.push({name: 'Interested', id: 16});
	  items.push({name: 'Amused', id: 17});
	  items.push({name: 'Optimistic', id: 18});
	  items.push({name: 'Deflated', id: 19});
	  items.push({name: 'Disappointed', id: 20});
	  items.push({name: 'Doubtful', id: 21});
	  items.push({name: 'Focused', id: 22});
	  items.push({name: 'Contented', id: 23});
	  items.push({name: 'Pleased', id: 24});
	  items.push({name: 'Sadness', id: 25});
	  items.push({name: 'Despondent', id: 26});
	  items.push({name: 'Disheartened', id: 27});
	  items.push({name: 'Thoughtful', id: 28});
	  items.push({name: 'Satisfied', id: 29});
	  items.push({name: 'Relaxed', id: 30});
	  items.push({name: 'Pessimistic', id: 31});
	  items.push({name: 'Apathetic', id: 32});
	  items.push({name: 'Complacent', id: 33});
	  items.push({name: 'Tranquil', id: 34});
	  items.push({name: 'Composed', id: 35});
	  items.push({name: 'Calm', id: 36});
	  
	  useEffect(() => {
		fetchData();
		}, [])
	  

	const generateReportDone = (csvData) => {
		console.log(csvData)
		setData(csvData)
	}
	
	function fetchData() {
		console.log('fetching data')
		new Api().generateReport(group_member.group_member_id, generateReportDone)
	}

	function renderGridItem(props) {
	const opponents_who_selected = opponents.filter(opponent => opponent.response_about_me != null).filter(opponent => opponent.response_about_me.response_id === props.id);
	const selectedCount = opponents_who_selected.length;
	return (
	<View style={props.id === selectedId ? styles.selecteditem : styles.item}  >
	  <View style={styles.flex} />
	  <Text style={styles.name} >
		{props.name} {selectedCount == 0 ? '' : '(' + selectedCount + ')'}
	  </Text>
	</View>
	)
	};
	
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
      <NavigationBar navigation={navigation}></NavigationBar>
      <View style={styles.container}>
        <Heading>Execute Scenario</Heading>
        <VerticalSpace height={20} />
		<Text style={styles.sectionHeader}>Opponents</Text>
        <VerticalSpace height={20} />
		<View
          style={styles.ScrollViewDriver}>
				<Text style={styles.tableData1S} onPress={() => doReviewOpponent(group_member)}>me</Text>
          {opponents.map((c, i) => (
            <View style={styles.table}>
				<Text style={styles.tableData1S} onPress={() => doReviewOpponent(c)}>{c.first_name} {c.last_name}</Text>
            </View>
          ))}
				<Text style={styles.tableData1S} onPress={() => doReviewOpponent(group_member)}>feedback</Text>
        </View>
        <VerticalSpace height={20} />
		<Text style={styles.sectionHeader}>Currently reviewing: {reviewee.first_name} {reviewee.last_name}</Text>
        <VerticalSpace height={20} />
			{ data === null ? '' : <CSVLink data={data} filename='report.csv' target='_blank'>Download report</CSVLink> }
        <VerticalSpace height={40} />
				<Text style={styles.tableData1S} onPress={() => PrintReport(group_member)}>Download PDF</Text>
        <VerticalSpace height={40} />
		<View style={styles.flex}>
			<FlatGrid
			  itemDimension={100}
			  extraData={
				selectedId     // for single item
			  }
			  data={items}
			  renderItem={({ item }) => renderGridItem(item)}
			/>
		</View>
        <DefaultButton onPress={onContinue} text="Continue"/>
      </View>
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding
  },
  ScrollViewDriver: {
	  maxWidth: '100%'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  outerContainer: {
    alignSelf: 'center',
    maxWidth: 800,
    width: '100%'
  },
  item: {
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center',
	  cursor: 'pointer'
  },
  notselected: {
	  backgroundColor: 'darkgray'
  },
  selecteditem: {
	  backgroundColor: 'lightgray',
	  borderStyle: 'solid',
	  borderWidth: 1,
	  textAlign: 'center'
  }
})
