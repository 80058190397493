import React, { useState, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import {useDropzone} from 'react-dropzone'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import UploadUserPreviewTable from '../screens/UploadUserPreviewTable'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import XLSX from 'xlsx';
import Api from '../components/Api'

interface UploadUsersProps {
  navigation: any
  onContinue: Function
  gotopage: Function
}


export default function UploadUsers ({ navigation, onContinue, gotopage }: UploadUsersProps) {
	const [uploaded_preview_data, setFile] = useState(null)
	const [uploaded_full_data_raw, setFullFile] = useState(null)
	const [uploaded_full_data_json, setJsonFile] = useState(null)
	const [preview_data_loaded, SetPreviewDataLoaded] = useState(false)
	const [first_name_col, SetFirstNameCol] = useState(-1)
	const [last_name_col, SetLastNameCol] = useState(-1)
	const [membership_num_col, SetMemNumCol] = useState(-1)
	const [email_col, SetEmailCol] = useState(-1)
	

const onDrop = useCallback(acceptedFiles => {
    console.log('dropped')
	PreviewFile(acceptedFiles)
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
	const canContinue = () => (uploaded_preview_data != null)

  function doContinue()
  {
	  //onContinue(firstName, lastName, email, membershipNumber)
  }
  
	function handleMapping(row_id, e)
	{
		if (e.target.value == "firstname") {
			SetFirstNameCol(row_id)
		}
		else if (e.target.value == "lastname") {
			SetLastNameCol(row_id)
		}
		else if (e.target.value == "membership_number") {
			SetMemNumCol(row_id)
		}
		else if (e.target.value == "email") {
			SetEmailCol(row_id)
		}
	}

  	function handleSubmit()
	{
		for (var j = 1; j < uploaded_full_data_raw.length; j++) { // for each line
			var first_name = "";
			var last_name = "";
			var membership_number = "";
			var email = "";
			const arrayOfValues = uploaded_full_data_raw[j].replace("\n","").split(',')
		console.log(arrayOfValues)
			if (first_name_col >= 0)
			{
				first_name = arrayOfValues[first_name_col];
			}
			if (last_name_col >= 0)
			{
				last_name = arrayOfValues[last_name_col];
			}
			if (membership_num_col >= 0)
			{
				membership_number = arrayOfValues[membership_num_col];
			}
			if (email_col >= 0)
			{
				email = arrayOfValues[email_col];
			}
			
			if (email != "" && first_name != "" && last_name != "")
			{
				new Api().addNewUser(first_name, last_name, email, membership_number)
			}
		}
		onContinue()
	}


  function PreviewFile(acceptedFiles)
  {
	  acceptedFiles.forEach((file) => {
		  
      const reader = new FileReader()
	  const theApp = this;

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = function(e) {
		  var filename = file.name;
			// pre-process data
			var binary = "";
			var bytes = new Uint8Array(e.target.result);
			var length = bytes.byteLength;
			for (var i = 0; i < length; i++) {
			  binary += String.fromCharCode(bytes[i]);
			}
			// call 'xlsx' to read the file
			var wb = XLSX.read(binary, {type: 'binary', cellDates:true, cellStyles:true});
		  const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws, {header:1});
			/* Update state */
			console.log("Data>>>"+data);


		const arrayOfLines = data.split('\n')
		setFullFile(arrayOfLines.filter(e => e !== ''))
		var table = {};
    var row_headers = [];
    var line1 = [];
    var line2 = [];
		
		const arrayOfValues1 = arrayOfLines[0].split(',')
		const arrayOfValues2 = arrayOfLines[1].split(',')
		const arrayOfValues3 = arrayOfLines[2].split(',')
			//this.state.uploaded_preview_data.push(arrayOfLines[i]);
			for (var j = 0; j < arrayOfValues1.length; j++) { // for each line
				//this.state.uploaded_preview_data.push(arrayOfLines[i]);
				row_headers.push(arrayOfValues1[j]);
				line1.push(arrayOfValues2[j]);
				line2.push(arrayOfValues3[j]);
			} 			

			var valuesAsJson = "[";
			for (var j = 0; j < row_headers.length; j++) { // for each line
				valuesAsJson = valuesAsJson + "{\"id\":\"" + j + "\",\"header\":\"" + row_headers[j] + "\", \"example1\":\"" + line1[j] + "\", \"example2\":\"" + line2[j] + "\"}";
				if (j < row_headers.length-1)
				{
					valuesAsJson = valuesAsJson + ",";
				}
			}
			valuesAsJson = valuesAsJson + "]";
			valuesAsJson = valuesAsJson.replace(/(?:\r\n|\r|\n)/g, '');

		setFile(JSON.parse(valuesAsJson))
		SetPreviewDataLoaded(true)
	  }
      reader.readAsArrayBuffer(file)

    })
  }
  
		var body = <View style={styles.dropcontainer} >
				  <div {...getRootProps({className: 'dropzone'})}>
					<input {...getInputProps()} />
					<p>Drag 'n' drop some files here, or click to select files</p>
				  </div>
				</View>
		
		if (preview_data_loaded == true)
		{
			body = <UploadUserPreviewTable data={uploaded_preview_data} handleMapping={handleMapping} handleSubmit={handleSubmit} />
		}


  return (

    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Upload New Users</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listusers')}>List Users</a> -> Upload Users</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					{body}
				<VerticalSpace height={40} />
				<ConditionalButton onPress={handleSubmit} condition={canContinue} text="Continue"/>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('listusers')} text={'cancel'} />
				<VerticalSpace height={20} />
			</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  dropcontainer: {
	flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }
  
})
