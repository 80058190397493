import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, RefreshControl, Text, TouchableOpacity, CheckBox } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import Table from '../components/Table'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Api from '../components/Api'
import Moment from 'moment';

interface AddAttendeesProps {
  navigation: any
  data: any
  attendees: any
  onContinue: Function
  gotopage: Function
  addAttendee: Function
  addAttendeeNoReturn: Function
}

export default function AddAttendees ({ navigation, data, onContinue, addAttendee, addAttendeeNoReturn, attendees, gotopage }: AddAttendeesProps) {
	const [local_data, SetLocalData] = useState(data)
	const [testval, settestval] = useState(0)
	const [isAdding, setAdding] = useState(false)

	const IndeterminateCheckbox = React.forwardRef(
	  ({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef()
		const resolvedRef = ref || defaultRef

		React.useEffect(() => {
		  resolvedRef.current.indeterminate = indeterminate
		}, [resolvedRef, indeterminate])

		return (
		  <>
			<input type="checkbox" ref={resolvedRef} {...rest} />
		  </>
		)
	  }
	)
		
	function onItemCheck(e, item) {
		item.selected = e
		settestval(Moment())
  }

   const columns = React.useMemo(
     () => [
		{
			id: 'selected',
			Cell: ( props ) => (
            <div>
				{attendees.findIndex(item => item.enrolledUser.id === props.row.original.id) >= 0 ? null: <CheckBox value={props.row.original.selected} onValueChange={(e) => onItemCheck(e, props.row.original)} style={styles.checkbox}	/>}
				
            </div>
          ),
        },
		{
			Header: "First name",
			accessor: "first_name",
			filterable: true
		},
		{
			Header: "Last name",
			accessor: "last_name",
			filterable: true
		},
		{
			Header: "Email",
			accessor: "email",
			filterable: true
		},
		{
			Header: "Membership number",
			accessor: "membership_number",
			filterable: true
		},
		{
			Header: "",
			accessor: "id",
Cell: ( props ) => (
	  <div>
		{attendees.findIndex(item => item.enrolledUser.id === props.row.original.id) >= 0 ? null: <a href="#" onClick={e => doAddAttendee(props.row.original.id)}>Add</a>}
		</div>
      )
	  },
     ],
     []
   )

	function doAddAttendee(id) {
		addAttendee(id)
	}

	function doAddSelectedAttendees() {
		setAdding(true)
		local_data.forEach((item) => {
		  if (item.selected == true)
		  {
			  addAttendeeNoReturn(item.id)
		  }
		});
		setTimeout(function () {
        onContinue()
    }, 2000);
	}
	
	function checkAdding() {
		return !isAdding
	}

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Add attendees</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listevents')}>List Programmes</a> -> <a href='#' onClick={() => gotopage('viewevent')}>View Programme</a> -> Add Attendee</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<Table data={local_data} columns={columns} />
				<VerticalSpace height={40} />
				<ConditionalButton condition={checkAdding} onPress={doAddSelectedAttendees} text="Add Selected"/>
				<VerticalSpace height={20} />
				<DefaultButton onPress={onContinue} text="Return to event"/>
				<VerticalSpace height={20} />
			</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )

}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  }
  
})
