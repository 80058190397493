import React, { useState } from "react";
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Api from '../components/Api'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import ConditionalButton from '../components/ConditionalButton'
import Heading from '../components/Heading'
import Para from '../components/Para'

import Styles from '../constants/Styles'
import Colors from '../constants/Colors'

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  localStorage.removeItem('user');
  
 const updateEmail = (text: string) => {
	  setEmail(text)
  }

 const updatePassword = (text: string) => {
	  setPassword(text)
  }

  
  const validateForm = () => {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
	
	const api = new Api();
	api.authenticate(email, password, function done(data) {
			localStorage.setItem('user', JSON.stringify(email));
			props.loginSucceeded()
			
	}, function notFound() {
		// not found
		//this.state.error = "Error";
		console.log('not found');
	});
	
  }

  return (
  		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={44} >Login</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
					<FormGroup controlId="email" bsSize="large">
						<LabeledTextField placeholder="Required" label="Username" onChange={updateEmail} value={email}/>
					</FormGroup>
					<FormGroup controlId="password" bsSize="large">
						<LabeledTextField placeholder="Required" password={true} label="Password" onChange={updatePassword} value={password} type="password"/>
					</FormGroup>
					<VerticalSpace height={40} />
					<ConditionalButton onPress={handleSubmit} condition={validateForm} text="Submit"/>
				<VerticalSpace height={20} />
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>


  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  }
  
})
