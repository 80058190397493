import { StatusBar } from 'expo-status-bar';
import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import Welcome from './screens/Welcome'
import Splash from './screens/Splash'
import NewUser from './screens/NewUser'
import EditUser from './screens/EditUser'
import UploadUsers from './screens/UploadUsers'
import ListUsers from './screens/ListUsers'
import NewEvent from './screens/NewEvent'
import ListEvents from './screens/ListEvents'
import ViewEvent from './screens/ViewEvent'
import ViewEventEdit from './screens/ViewEventEdit'
import ViewScenario from './screens/ViewScenario'
import ViewReviewPoint from './screens/ViewReviewPoint'
import ViewScenarioPoint from './screens/ViewScenarioPoint'
import AddAttendees from './screens/AddAttendees'
import NewScenario from './screens/NewScenario'
import NewReviewPoint from './screens/NewReviewPoint'
import NewScenarioPoint from './screens/NewScenarioPoint'
import ViewUserScenario from './screens/ViewUserScenario'
import ViewUserScenarioReview from './screens/ViewUserScenarioReview'
import PrintPDFReport from './screens/PrintPDFReport'
import Login from './screens/Login'

import Api from './components/Api'




export default function App() {
	const [screenname, SetScreenName] = useState('splash')
	const [data, SetData] = useState('')
	const [isLoggedIn, SetLoggedIn] = useState(false)
	const [selected_event, SetSelectedEvent] = useState('')
	const [selected_attendee, SetSelectedAttendee] = useState('')
	const [selected_scenario, SetSelectedScenario] = useState('')
	const [selected_user, SetSelectedUser] = useState('')
	const [selected_scenario_point, SetSelectedScenarioPoint] = useState('')
	const [selected_review_point, SetSelectedReviewPoint] = useState('')
	const [eventdata, SetEventData] = useState('')
	const [scenarios, SetScenarios] = useState('')
	const [attendees, SetAttendees] = useState('')
	const [groups, SetGroups] = useState('')
	const [review_points, SetReviewPoints] = useState('')
	const [scenario_points, SetScenarioPoints] = useState('')
	const [scenario_groups, SetScenarioGroups] = useState('')
	const [opponents, SetOpponents] = useState('')
	const [opponent, SetOpponent] = useState('')
	const [group_member, SetGroupMember] = useState('')
	const [pdf_data, SetPDFData] = useState('')
	const [response_id, SetResponseId] = useState('')
	const [review_point_types, SetReviewPointTypes] = useState('')
	const [reviewed_self, SetReviewedSelf] = useState(null)
	
  
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		if(query.get('id') == null)
		{
			if (isLoggedIn == true)
			{
				SetScreenName('welcome')
			}
			else
			{
				SetScreenName('login')
			}
		}
	}, [])


	const loginSucceeded = () => {
		SetLoggedIn(true)
		SetScreenName('welcome')
	}
	
    const gotopage = (newscreenname) => {
		if (newscreenname == 'addattendee')
		{
			new Api().ListUsers(onListAttendeesDone)
		}
		else if (newscreenname == 'addreviewpoint')
		{
			new Api().ListReviewPointTypes(onListReviewPointTypesDone)
		}
		else
		{
			SetScreenName(newscreenname)
		}
  }
  
  const downloaddata = () => {
		new Api().DownloadData(onDownloadDataDone)
  }
  
  const onDownloadDataDone = (indata) => {
	  download(indata)
  }
  
  const download = function (data) {
 
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: 'text/csv' });
 
    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)
 
    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')
 
    // Passing the blob downloading url
    a.setAttribute('href', url)
 
    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', 'download.csv');
 
    // Performing a download with click
    a.click()
}

  const submitNewUser = (firstName, lastName, email, membershipNumber) => {
		new Api().addNewUser(firstName, lastName, email, membershipNumber)
		new Api().ListUsers(onListDone)
  }
  
	const uploadUsersContinue = () => {
		SetScreenName('listusers')
	}

	const deleteUser = (id) => {
		new Api().deleteUser(id, onDeleteDone)
		new Api().ListUsers(onListDone)
	}
	
	const editUser = (user) => {
		SetSelectedUser(user)
		SetScreenName('edituser')
	}

	const onListDone = (indata) => {
		console.log(indata)
		SetData(indata)
		SetScreenName('listusersfetched')
		return <ListUsers data={data} gotopage={gotopage} onContinue={() => gotopage('welcome')} deleteUser={deleteUser}  />
	}
	
	const onListAttendeesDone = (indata) => {
		SetData(indata)
		SetScreenName('addattendee')
	}
	
	const onListReviewPointTypesDone = (indata) => {
		console.log(indata)
		SetReviewPointTypes(indata)
		SetScreenName('addreviewpoint')
	}
	
	const onDeleteDone = () => {
		SetScreenName('listusers')
	}
	
	const onNewScenarioDone = () => {
		viewEvent(selected_event)
	}
	
	const onNewReviewPointDone = () => {
		viewScenario(selected_scenario)
	}
	
	const onNewScenarioPointDone = () => {
		viewScenario(selected_scenario)
	}

	const submitNewScenario = (name, template) => {
		new Api().AddNewScenario(selected_event.id, name, template, onNewScenarioDone)
	}
	
	const submitNewReviewPoint = (name, review_self, review_point_type) => {
		new Api().AddNewReviewPoint(selected_scenario_point.id, name, review_self, review_point_type, onNewReviewPointDone)
	}
	
	const showScenarioReport = (id, show) => {
		new Api().showScenarioReport(id, show, onShowScenarioReportDone)
	}
	
	const toggleReviewPoint = (id, show) => {
		new Api().toggleReviewPointLive(id, show, onToggleReviewPointDone)
	}

	const toggleReviewPointStay = (id, show) => {
		new Api().toggleReviewPointLive(id, show, onShowScenarioReportStayDone)
	}
	
	const onShowScenarioReportStayDone = () => {
	}

	const onShowScenarioReportDone = () => {
		viewEvent(selected_event)
	}

	const onToggleReviewPointDone = () => {
	  viewScenarioPoint(selected_scenario_point)
	}

	const submitNewScenarioPoint = (name) => {
		new Api().AddNewScenarioPoint(selected_scenario.id, name, onNewScenarioPointDone)
	}

  const submitNewEvent = (name, event_date) => {
		new Api().addNewEvent(name, event_date)
	  new Api().ListEvents(onListEventsDone)
  }
  
  const onAddAttendeeDone = () =>
  {
	  viewEvent(selected_event)
  }
  
  const ReviewOpponent = (opponent) =>
  {
	  console.log(opponent)
	  console.log(group_member)
	  if (opponent.id == group_member.id)
	  {
		//opponent.first_name = "me"
		//opponent.last_name += " (me)"
		opponent.group_member_id = group_member.id
	  }
	  SetOpponent(opponent)
	  new Api().getGroupMemberResponse(group_member.id, opponent.group_member_id, onGetGroupMemberResponseDone)
  }
  
  const PrintReport = () =>
  {
  	  SetScreenName('printreport')
  }
  
  const ViewMySummary = () =>
  {
	  console.log('mysumm')
	  console.log(group_member)
  	  SetOpponent(group_member)
  	  new Api().getGroupMemberResponse(group_member.group_member_id, group_member.group_member_id, onGetGroupMemberResponseDoneFeedback)

  }
  
  const onGetGroupMemberResponseDone = (group_member_in, response_id) =>
  {
	  SetResponseId(response_id)
  	  SetScreenName('viewuserscenario')	  
  }

  const onGetGroupMemberResponseDoneFeedback = (group_member_in, response_id) =>
  {
	  SetResponseId(response_id)
  	  SetOpponent(group_member_in)
	  SetScreenName('viewmysummary')
  }
  
  const addAttendee = (id) => {
	  new Api().addAttendee(id, selected_event.id, onAddAttendeeDone)
  }

  const addAttendeeNoReturn = (id) => {
	  new Api().addAttendee(id, selected_event.id, null)
  }
  
  const onAttendeeRemovedDone = () => {
	  viewEvent(selected_event)
  }
  
  const onScenarioRemoved = () => {
	  viewEvent(selected_event)
  }
  
  const onReviewPointRemoved = () => {
	  viewScenario(selected_scenario)
  }
  
  const onScenarioPointRemoved = () => {
	  viewScenario(selected_scenario)
  }
  
  const onAttendeeRemoved = () => {
	  new Api().ListEvents(onAttendeeRemovedDone)
  }
  
  const removeAttendee = (id) => {
	  new Api().removeAttendee(id, onAttendeeRemoved)	  
  }
  
  const viewPDFReport = (attendee_id, scenario) => {
	  SetSelectedScenario(scenario)
	  new Api().getAttendee(attendee_id, scenario, getAttendeeDone)
  }
  
  	const getAttendeeDone = (attendee, scenario) => {
	  SetSelectedAttendee(attendee)
		new Api().GetScenarioResults(attendee.id, scenario.id, fetchedPDFDataDone)
	}

  	const fetchedPDFDataDone = (inData) => {
		SetPDFData(inData)
   	  SetScreenName('printreport')
	}


	function fetchData() {
		console.log('fetching data')
	}

  
  const onOpponentsListed = (opponents) => {
	  viewUserScenario(opponents)
  }
  
  const execute_review_point = (group_member_in, review_point) => {
	  //group_member_in.first_name = "me"
	  //group_member_in.last_name = ""
	  console.log(group_member_in)
	  SetGroupMember(group_member_in)
	  SetOpponent(group_member_in)
	  SetSelectedReviewPoint(review_point)
	  new Api().getGroupMemberResponse(group_member_in.id, group_member_in.id, onGetGroupMemberResponseDone2)
  }
  
  const onGetGroupMemberResponseDone2 = (group_member_in_id, response_id) => {
  	  SetResponseId(response_id)
	  console.log(group_member_in_id)
	  SetReviewedSelf(response_id)
	  new Api().listOpponents(group_member_in_id, onOpponentsListed)	  
  }
  
  const removeScenario = (id) => {
	  new Api().deleteScenario(id, onScenarioRemoved)	  
  }
  
  const removeReviewPoint = (id) => {
	  new Api().deleteReviewPoint(id, onReviewPointRemoved)	  
  }
  
  const removeScenarioPoint = (id) => {
	  new Api().deleteScenarioPoint(id, onScenarioPointRemoved)	  
  }

	const deleteEvent = (id) => {
		new Api().deleteEvent(id, onDeleteEventDone)
	}
	
	const onGetScenariosDone = (event_scenarios) => {
		SetScenarios(event_scenarios)
	  SetScreenName('viewevent')		
	}
	
	const onGetAttendeesDone = (event_attendees, ev) => {
		SetAttendees(event_attendees)
		new Api().ListScenarios(ev.id, onGetScenariosDone)
	}
	
	const viewEvent = (ev) => {
		SetSelectedEvent(ev)
		new Api().ListAttendees(ev.id, onGetAttendeesDone, ev)
	}
	
	const viewUserScenario = (opponents) => {
		console.log(opponents);
		SetOpponents(opponents.group_members)
		SetScreenName('viewuserscenario')
	}

	const onListReviewPointGroupsDone = (groups) => {
		SetGroups(groups)
	  SetScreenName('viewreviewpoint')
	}

	const onListReviewPointsDone = (review_points) => {
		SetReviewPoints(review_points)
	  SetScreenName('viewscenariopoint')
	}

	const onListScenarioPointsDone = (scenario_points) => {
		SetScenarioPoints(scenario_points)
		SetScreenName('viewscenario')

		//new Api().ListReviewPointGroups(selected_review_point.id, onListGroupsDone)
	}
	
	const onListGroupsDone = (groups) => {
		SetGroups(groups)
	  SetScreenName('viewscenario')
	}

	const viewScenario = (scenario) => {
		SetSelectedScenario(scenario)
		new Api().ListScenarioPoints(scenario.id, onListScenarioPointsDone)
	}

	const viewReviewPoint = (review_point) => {
		SetSelectedReviewPoint(review_point)
		new Api().ListReviewPointGroups(review_point.id, onListReviewPointGroupsDone)
	}

	const viewScenarioPoint = (scenario_point) => {
		SetSelectedScenarioPoint(scenario_point)
		new Api().ListReviewPoints(scenario_point.id, onListReviewPointsDone)
	}

	const onListEventsDone = (indata) => {
		SetEventData(indata)
		SetScreenName('listeventsfetched')
		return <ListEvents data={eventdata} gotopage={gotopage} onContinue={() => gotopage('welcome')} deleteEvent={deleteEvent}  />
	}
	
	const onDeleteEventDone = () => {
		SetScreenName('listevents')
	}
	
	const EndEditing = () => {
		viewEvent(selected_event)
	}
	
	const StartEditing = () => {
		SetScreenName('vieweventedit')
	}
	
  const screen = (): ReactElement => {
	  if (screenname == 'splash') {
			return <Splash gotopage={gotopage} onContinue={() => setWelcomed(true)} execute_review_point={execute_review_point} viewPDFReport={viewPDFReport} />
	  }
	  else if (screenname == 'login') {
			return <Login loginSucceeded={loginSucceeded} />
	  }
	  else if (screenname == 'welcome') {
			return <Welcome gotopage={gotopage} onContinue={() => setWelcomed(true)} downloaddata={downloaddata} />
	  }
	  else if (screenname == 'newuser') {
			return <NewUser gotopage={gotopage} onContinue={submitNewUser} />
	  }
	  else if (screenname == 'edituser') {
			return <EditUser onContinue={() => gotopage('listusers')} gotopage={gotopage} user={selected_user} />
	  }
	  else if (screenname == 'uploadusers') {
			return <UploadUsers gotopage={gotopage} onContinue={uploadUsersContinue} />
	  }
	  else if (screenname == 'listusers') {
			new Api().ListUsers(onListDone)
			return <Welcome gotopage={gotopage} onContinue={() => setWelcomed(true)} />
	  }
	  else if (screenname == 'listusersfetched') {
			return <ListUsers data={data} gotopage={gotopage} onContinue={() => gotopage('welcome')} deleteUser={deleteUser} editUser={editUser} />
	  }
	  else if (screenname == 'newevent') {
			return <NewEvent gotopage={gotopage} onContinue={submitNewEvent} />
	  }
	  else if (screenname == 'listevents') {
			new Api().ListEvents(onListEventsDone)
			return <Welcome gotopage={gotopage} onContinue={() => setWelcomed(true)} />
	  }
	  else if (screenname == 'listeventsfetched') {
			return <ListEvents data={eventdata} gotopage={gotopage} onContinue={() => gotopage('welcome')} deleteEvent={deleteEvent} viewEvent={viewEvent} />
	  }
	  else if (screenname == 'viewevent') {
		  if (selected_event != '') {
			return <ViewEvent StartEditing={StartEditing} selected_event={selected_event} onContinue={() => gotopage('listeventsfetched')} gotopage={gotopage} removeAttendee={removeAttendee} scenarios={scenarios} attendees={attendees} removeScenario={removeScenario} viewReviewPoint={viewReviewPoint} viewScenario={viewScenario} toggleReviewPoint={toggleReviewPointStay} showScenarioResults={showScenarioReport} />
		  }
		  else
		  {
			  gotopage('listevents')
			  return <Welcome gotopage={gotopage} onContinue={() => setWelcomed(true)} />
		  }
	  }
	  else if (screenname == 'vieweventedit') {
		  if (selected_event != '') {
			return <ViewEventEdit EndEditing={EndEditing} selected_event={selected_event} onContinue={() => gotopage('listeventsfetched')} gotopage={gotopage} removeAttendee={removeAttendee} scenarios={scenarios} attendees={attendees} removeScenario={removeScenario} viewReviewPoint={viewReviewPoint} viewScenario={viewScenario} toggleReviewPoint={toggleReviewPointStay} showScenarioResults={showScenarioReport} />
		  }
		  else
		  {
			  gotopage('listevents')
			  return <Welcome gotopage={gotopage} onContinue={() => setWelcomed(true)} />
		  }
	  }
	  else if (screenname == 'viewscenario') {
		  if (selected_scenario != '') {
			return <ViewScenario selected_scenario={selected_scenario} removeScenarioPoint={removeScenarioPoint} viewScenarioPoint={viewScenarioPoint} onContinue={() => viewEvent(selected_event)} gotopage={gotopage} scenario_points={scenario_points} groups={groups} viewPDFReport={viewPDFReport} />
		  }
		  else
		  {
			  gotopage('viewevent')
			  return <Welcome gotopage={gotopage} onContinue={() => setWelcomed(true)} />
		  }
	  }
	  else if (screenname == 'viewscenariopoint') {
		  if (selected_scenario_point != '') {
			return <ViewScenarioPoint selected_scenario_point={selected_scenario_point} removeReviewPoint={removeReviewPoint} viewReviewPoint={viewReviewPoint} onContinue={() => gotopage('viewscenario')} gotopage={gotopage} review_points={review_points} toggleReviewPoint={toggleReviewPoint} />
		  }
		  else
		  {
			  gotopage('viewscenario')
			  return <Welcome gotopage={gotopage} onContinue={() => setWelcomed(true)} />
		  }
	  }
	  else if (screenname == 'viewreviewpoint') {
			return <ViewReviewPoint selected_review_point={selected_review_point} onContinue={() => gotopage('viewscenariopoint')} gotopage={gotopage} groups={groups} execute_review_point={execute_review_point} />
	  }
	  else if (screenname == 'addattendee') {
			return <AddAttendees data={data} onContinue={() => viewEvent(selected_event)} gotopage={gotopage} addAttendee={addAttendee} attendees={attendees} addAttendeeNoReturn={addAttendeeNoReturn} />
	  }
	  else if (screenname == 'addscenario') {
			return <NewScenario gotopage={gotopage} onContinue={submitNewScenario} />
	  }
	  else if (screenname == 'addscenariopoint') {
			return <NewScenarioPoint gotopage={gotopage} onContinue={submitNewScenarioPoint} />
	  }
	  else if (screenname == 'addreviewpoint') {
			return <NewReviewPoint gotopage={gotopage} onContinue={submitNewReviewPoint} reviewPointTypes={review_point_types} />
	  }
	  else if (screenname == 'viewuserscenario') {
			return <ViewUserScenario onContinue={() => gotopage('splash')} group_member={group_member} opponents={opponents} gotopage={gotopage} reviewee={opponent} ReviewOpponent={ReviewOpponent} ViewMySummary={ViewMySummary} selected_scenario={selected_scenario} selected_review_point={selected_review_point} selected_scenario_point={selected_scenario_point} response_id={response_id} SetResponseId={SetResponseId} reviewed_self={reviewed_self} SetReviewedSelf={SetReviewedSelf} />
	  }
	  else if (screenname == 'viewmysummary') {
			return <ViewUserScenarioReview onContinue={() => gotopage('welcome')} group_member={group_member} opponents={opponents} reviewee={opponent} ReviewOpponent={ReviewOpponent} PrintReport={PrintReport} response_id={response_id} />
	  }
	  else if (screenname == 'printreport') {
		  console.log(selected_attendee)
		return <PrintPDFReport onContinue={() => gotopage('welcome')} selected_attendee={selected_attendee} selected_scenario={selected_scenario} pdf_data={pdf_data} />
	  }
  }
	
	const Stack = createStackNavigator()
	
  return (
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            cardStyle: {
              backgroundColor: '#ffffff' // Shine
            },
            headerShown: false
          }}>
          <Stack.Screen name="Welcome" component={screen} />
        </Stack.Navigator>
      </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
