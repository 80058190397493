import React from 'react'
import { Text } from 'react-native'

type HeadingProps = {
  children: string
  fontsize: any
  color: any
}

const Heading = ({ children, fontsize, color }: HeadingProps) => {
  return <Text style={{ color: color ? color : '#333', fontSize: fontsize ? fontsize: 28, fontWeight: 'bold', marginBottom: 5, marginTop: 15, fontFamily: "NoeDisplayMedium" }}>{children}</Text>
}

export default Heading