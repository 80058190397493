import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, RefreshControl, Text, TouchableOpacity } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import Table from '../components/Table'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Moment from 'moment';

import Api from '../components/Api'


interface ListUsersProps {
  navigation: any
  data: any
  onContinue: Function
  gotopage: Function
  deleteUser: Function
  editUser: Function
}

export default function ListUsers ({ navigation, onContinue, gotopage, data, deleteUser, editUser }: ListUsersProps) {

	function handleDelete(id) {
		confirmAlert({
		  title: 'Confirm delete',
		  message: 'Are you sure you want to do this.',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => deleteUser(id)
			},
			{
			  label: 'No'
			}
		  ]
		});
	}
	
	function handleEdit(user) {
		editUser(user)
	}

   const columns = React.useMemo(
     () => [
       {
        Header: "First name",
        accessor: "first_name",
		filterable: true
		},
		{
			Header: "Last name",
			accessor: "last_name",
			filterable: true
		},
		{
			Header: "Email",
			accessor: "email",
			filterable: true
		},
		{
			Header: "Membership number",
			accessor: "membership_number",
			filterable: true
		},
		{
			Header: "Added",
			Cell: (props) => (
			  <div>
			  {Moment(props.row.original.dateTimeAdded).format("DD/MM/YYYY")}
				</div>
			)
		},
		{
			Header: "",
			accessor: "id",
Cell: ( props ) => (
	  <div>
		<a href="#" onClick={e => handleEdit(props.row.original)}>Edit</a>
		<span> | </span>
		<a href="#" onClick={e => handleDelete(props.row.original.id)}>Delete</a>
		</div>
      )
	  },
     ],
     []
   )
   
  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Users</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> List Users</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<DefaultButton style={{color: 'blue'}} onPress={() => gotopage('newuser')} text={'Add User'} />
				<VerticalSpace height={20} />
				<DefaultButton style={{color: 'blue'}} onPress={() => gotopage('uploadusers')} text={'Upload Users'} />
				<VerticalSpace height={20} />
				<Table data={data} columns={columns} pages={true} />
				<VerticalSpace height={40} />
				<DefaultButton onPress={onContinue} text="Return to menu"/>
				<VerticalSpace height={20} />
			</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )

}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  }
  
})
