import React, { FunctionComponent } from 'react'
import { Image, Linking, StyleSheet, TouchableOpacity, View } from 'react-native'
import Styles from '../constants/Styles'
import Para from '../components/Para'

type NavigationBarIconsProps = {
  navigation: any,
  name: any
}

const NavigationBarIcons: FunctionComponent<NavigationBarIconsProps> = ({ navigation, name }) => {
  return (
    <View style={styles.navigationBarIcons}>
      <Image style={styles.navigationBarLogo} source={require('../assets/SM-Logo.webp')}></Image>
		{name != null ? <Para style={styles.participantName}>Participant:<br />{name}</Para> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBarIcons: {
    display: 'flex',
	maxWidth: 1000,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Styles.standardPadding
  },
  navigationBarLogo: {
    height: 76,
    width: 244,
	marginTop: 3
  },
  participantName: {
	  float: 'right',
	  paddingTop: 10,
  }
})

export default NavigationBarIcons
