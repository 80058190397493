
//import React from 'react';
//import Table from './components/Table';

import React, { Component } from "react";
import { render } from "react-dom";
import { Button } from "react-bootstrap";

import Table from '../components/Table'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

import { css } from '@emotion/react'
import CircleLoader from 'react-spinners/CircleLoader';


import API from '../API';
//import '../App.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: #ccc;
`;

class UploadUserPreviewTable extends Component {
  constructor(props) {
    super(props);

    this.idleTimeout = 30 * 60 * 1000;
	
    this.state = {
		loading: false,
		data: props.data,
		columns: [{
        Header: "Column header",
        accessor: "header"
    },
    {
        Header: "Example row 1",
        accessor: "example1"
    },
    {
        Header: "Example row 2",
        accessor: "example2"
    },
    {
        Header: "Map to field",
		accessor: "id",
		Cell: ({ row }) => (
		<div>
			<select onChange={e => props.handleMapping(row.id, e)}>
			  <option value="-">Do not map</option>
			  <option value="firstname">First name</option>
			  <option value="lastname">Last name</option>
			  <option value="membership_number">Membership number</option>
			  <option value="email">Email</option>
			</select>			
		</div>
		 )		
    }],
    };
}

componentDidMount() {
	this.updateData();
}

updateData()
{
}

  validateForm() {
    return true;
  }


render() {
	if (this.state.loading == true)
	{
		return(
	<div className='sweet-loading'>
        <CircleLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.loading}
        />
      </div> 
	  );

	}
	else
	{
  return(
  <div>
  <Table data={this.state.data} columns={this.state.columns} hideSearch={true} />
    </div>
  );
  
}





}
}


  
export default UploadUserPreviewTable;
