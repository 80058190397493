import React from 'react'
import { StyleSheet, View } from 'react-native'
import Colors from '../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TabOneScreen from '../screens/Welcome';
import TabTwoScreen from '../screens/Welcome';


type NavigationBarProps = {
  navigation: any
  name: any
}

const NavigationBar = ({ navigation, name }: NavigationBarProps) => {
  return (
    <View style={styles.navigationBar}>
      <NavigationBarIcons navigation={navigation} name={name}></NavigationBarIcons>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    // backgroundColor: Colors.primaryPurple, // AP
    backgroundColor: Colors.white, // Shine
    // color: Colors.white, // AP
    color: Colors.dark, // Shine
    paddingBottom: 15,
    paddingTop: 40
  }
})

const BottomTab = createBottomTabNavigator<RootTabParamList>();


export default NavigationBar
