import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'

interface WelcomeProps {
  navigation: any
  onContinue: Function
  gotopage: Function
  downloaddata: Function
}


export default function Welcome ({ navigation, onContinue, gotopage, downloaddata }: WelcomeProps) {

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Welcome</Heading>
				Home
			<VerticalSpace height={10} />
			</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<Para>Select an option from the list below</Para>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('listusers')} text={'List Users'} />
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('listevents')} text={'List Programmes'} />
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => downloaddata()} text={'Download data'} />
				<VerticalSpace height={20} />
		</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
	color: '#000000'
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  }
  
})
