import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import Colors from '../constants/Colors'
import NavigationBarIcons from '../components/NavigationBarIcons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Styles from '../constants/Styles'
import { useTable, useFilters, useAsyncDebounce, useSortBy, usePagination } from 'react-table'


type TableProps = {
  columns: any
  data: any
  hideSearch: any
  pages: any
}

export default function Table ({ columns, data, hideSearch, pages }: TableProps) {
	
	function DefaultColumnFilter({
	  column: { filterValue, preFilteredRows, setFilter },
	}) {
	  const count = preFilteredRows.length
	  return (
		<input
		  value={filterValue || ''}
		  onChange={e => {
			setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
		  }}
		  placeholder={`Search`}
		/>
			)
		}

  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )


  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

   const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
	 page,
	 canPreviousPage,
	 canNextPage,
	 pageOptions,
	 nextPage,
	 previousPage,
	 setPageSize,
	state: { pageIndex, pageSize },
   } = useTable({ columns, data, defaultColumn, filterTypes, initialState: { pageIndex: 0, pageSize: 20 } }, useFilters, useSortBy, usePagination)
	
  return (
			<View>
				<table {...getTableProps()} style={{ border: 'none' }}>
					   <thead>
						{headerGroups.map(headerGroup => (
						   <tr {...headerGroup.getHeaderGroupProps()}>
							 {headerGroup.headers.map(column => (
							   <th
								 {...column.getHeaderProps(column.getSortByToggleProps())}
								 style={{
									   padding: '10px',
									   border: 'none',
									   backgroundColor: '#cccccc',
									   textAlign: 'left',
								 }}
							   >
								 {column.render('Header')}
									 {hideSearch ? null : <div>{column.canFilter ? (column.render('Header') == "" ? null : column.render('Filter')) : null}</div>}
								<span>
									{column.isSorted
									  ? column.isSortedDesc
										? ' 🔽'
										: ' 🔼'
									  : ''}
								  </span>
								  </th>
							 ))}
						   </tr>
						 ))}
					   </thead>
					   <tbody {...getTableBodyProps()}>
						 {page.map((row, i) => {
						   prepareRow(row)
						   return (
							 <tr {...row.getRowProps()}>
							   {row.cells.map(cell => {
								 return (
								   <td
									 {...cell.getCellProps()}
									 style={{
									   padding: '10px',
									   backgroundColor: '#eeeeee',
									 }}
								   >
									 {cell.render('Cell')}
								   </td>
								 )
							   })}
							 </tr>
						   )
						 })}
					   </tbody>
					 </table>
					 {data.length > 20 ? <div className="pagination">					 
						<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						  {'<'}
						</button>{' '}
						{pageIndex + 1} of {pageOptions.length}{' '}
						<button onClick={() => nextPage()} disabled={!canNextPage}>
						  {'>'}
						</button>{' '}
					 </div> : null}
			</View>
  )
}

const styles = StyleSheet.create({
  navigationBar: {
    // backgroundColor: Colors.primaryPurple, // AP
    backgroundColor: Colors.black, // Shine
    color: Colors.white, // Shine
    paddingBottom: 40,
    paddingTop: 40,
  },
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },

})

