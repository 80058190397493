import React, { useState, useRef, useEffect } from 'react'
 
import {TouchableHighlight} from 'react-native';
 
import { PDFDownloadLink, Document, Page, Font, StyleSheet, Image, Text, View } from '@react-pdf/renderer';

import leftarrow from '../assets/leftarrow.png'
import rightarrow from '../assets/rightarrow.png'
import uparrow from '../assets/uparrow.png'
import downarrow from '../assets/downarrow.png'

import bluecat from '../assets/bluecat.png'
import redcat from '../assets/redcat.png'
import yellowcat from '../assets/yellowcat.png'
import greencat from '../assets/greencat.png'

import bluecat2 from '../assets/bluecat2.png'
import redcat2 from '../assets/redcat2.png'
import yellowcat2 from '../assets/yellowcat2.png'
import greencat2 from '../assets/greencat2.png'
import greycat2 from '../assets/greycat2.png'

import transitionarrow from '../assets/transitionarrow.png'
import palette from '../assets/palette.png'

import Api from '../components/Api'

import font from '../assets/NoeDisplayMedium.otf'
import pdficon from '../assets/report_icon.png';
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'

 
interface PrintPDFReportProps {
  onContinue: Function
  selected_scenario: any
  selected_attendee: any
  show_as_link: any
}


export default function PrintPDFReport ({ onContinue, selected_scenario, selected_attendee, show_as_link }: PrintPDFReportProps) {
	const [pdf_data, SetPDFData] = useState(null)
	
	useEffect(() => {
		if (selected_scenario != null && selected_attendee != null && show_as_link == "false")
		{
			new Api().GetScenarioResults(selected_attendee.id, selected_scenario.id, fetchedPDFDataDone)
		}
	}, []);
	
  	const fetchedPDFDataDone = (inData) => {
		//console.log(inData)
		SetPDFData(inData)
	}
	
	const GeneratePDF = () => {
		new Api().GetScenarioResults(selected_attendee.id, selected_scenario.id, fetchedPDFDataDone)
	}
	
	// Create Document Component
const MyDocument = () => (
  <Document>
	{pdf_data.scenario_points == null ? null : pdf_data.scenario_points.map((c, i) => (
	<>
  <Page size="A4" orientation="landscape" style={styles.whitebody}>
		<Image style={styles.page1Palette1} src={palette}/>
		<Image style={styles.page1Palette2} src={palette}/>
      <Text style={styles.smallprint}>
        Savage Macbeth Commercial Conflict Resolution Programme
      </Text>
      <Text style={styles.title} render={({ pageNumber }) => (
        `${pageNumber} . Emotion Awareness Tool results – understanding MY emotions`
      )} />
      <Text style={styles.subtitle}>{selected_attendee.enrolledUser.first_name} {selected_attendee.enrolledUser.last_name}, {selected_scenario.name}, {c.name}</Text>
	  <View style={styles.panel12}>
		<View style={styles.panel6}>
			<Text>YOU said you were:</Text>
			<Text style={styles.text}></Text>
			<View style={styles.headertable}>
			  <Text style={[styles.gridspacevert]}></Text>
			  <Image style={styles.gridimagevert} src={redcat}/>
			  <Text style={[styles.gridspacevert2]}></Text>
			  <Image style={styles.gridimagevert} src={uparrow}/>
			</View>
			<View style={styles.table}>
				  <View style={[styles.leftcolumn]}>
					  <Text style={[styles.gridspace]}></Text>
					  <Image style={styles.gridimage} src={leftarrow}/>
					  <Text style={[styles.gridspace1]}></Text>
					  <Image style={styles.gridimage} src={bluecat}/>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.myresponseaboutme == 1 ? [styles.cell, styles.selected] : [styles.cell]}>Angry</Text>
					  <Text style={c.myresponseaboutme == 7 ? [styles.cell, styles.selected] : [styles.cell]}>Disgusted</Text>
					  <Text style={c.myresponseaboutme == 13 ? [styles.cell, styles.selected] : [styles.cell]}>Irritated</Text>
					  <Text style={c.myresponseaboutme == 19 ? [styles.cell, styles.selected] : [styles.cell]}>Deflated</Text>
					  <Text style={c.myresponseaboutme == 25 ? [styles.cell, styles.selected] : [styles.cell]}>Sad</Text>
					  <Text style={c.myresponseaboutme == 31 ? [styles.cell, styles.selected] : [styles.cell]}>Pessimistic</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.myresponseaboutme == 2 ? [styles.cell, styles.selected] : [styles.cell]}>Annoyed</Text>
					  <Text style={c.myresponseaboutme == 8 ? [styles.cell, styles.selected] : [styles.cell]}>Contemptuous</Text>
					  <Text style={c.myresponseaboutme == 14 ? [styles.cell, styles.selected] : [styles.cell]}>Pained</Text>
					  <Text style={c.myresponseaboutme == 20 ? [styles.cell, styles.selected] : [styles.cell]}>Disappointed</Text>
					  <Text style={c.myresponseaboutme == 26 ? [styles.cell, styles.selected] : [styles.cell]}>Despondent</Text>
					  <Text style={c.myresponseaboutme == 32 ? [styles.cell, styles.selected] : [styles.cell]}>Apathetic</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.myresponseaboutme == 3 ? [styles.cell, styles.selected] : [styles.cell]}>Fearful</Text>
					  <Text style={c.myresponseaboutme == 9 ? [styles.cell, styles.selected] : [styles.cell]}>Surprised</Text>
					  <Text style={c.myresponseaboutme == 15 ? [styles.cell, styles.selected] : [styles.cell]}>Confused</Text>
					  <Text style={c.myresponseaboutme == 21 ? [styles.cell, styles.selected] : [styles.cell]}>Doubtful</Text>
					  <Text style={c.myresponseaboutme == 27 ? [styles.cell, styles.selected] : [styles.cell]}>Disheartened</Text>
					  <Text style={c.myresponseaboutme == 33 ? [styles.cell, styles.selected] : [styles.cell]}>Complacent</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.myresponseaboutme == 4 ? [styles.cell, styles.selected] : [styles.cell]}>Awed</Text>
					  <Text style={c.myresponseaboutme == 10 ? [styles.cell, styles.selected] : [styles.cell]}>Keen</Text>
					  <Text style={c.myresponseaboutme == 16 ? [styles.cell, styles.selected] : [styles.cell]}>Interested</Text>
					  <Text style={c.myresponseaboutme == 22 ? [styles.cell, styles.selected] : [styles.cell]}>Focused</Text>
					  <Text style={c.myresponseaboutme == 28 ? [styles.cell, styles.selected] : [styles.cell]}>Thoughtful</Text>
					  <Text style={c.myresponseaboutme == 34 ? [styles.cell, styles.selected] : [styles.cell]}>Tranquil</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.myresponseaboutme == 5 ? [styles.cell, styles.selected] : [styles.cell]}>Triumphant</Text>
					  <Text style={c.myresponseaboutme == 11 ? [styles.cell, styles.selected] : [styles.cell]}>Hopeful</Text>
					  <Text style={c.myresponseaboutme == 17 ? [styles.cell, styles.selected] : [styles.cell]}>Amused</Text>
					  <Text style={c.myresponseaboutme == 23 ? [styles.cell, styles.selected] : [styles.cell]}>Contented</Text>
					  <Text style={c.myresponseaboutme == 29 ? [styles.cell, styles.selected] : [styles.cell]}>Satisfied</Text>
					  <Text style={c.myresponseaboutme == 35 ? [styles.cell, styles.selected] : [styles.cell]}>Composed</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.myresponseaboutme == 6 ? [styles.cell, styles.selected] : [styles.cell]}>Elated</Text>
					  <Text style={c.myresponseaboutme == 12 ? [styles.cell, styles.selected] : [styles.cell]}>Happy</Text>
					  <Text style={c.myresponseaboutme == 18 ? [styles.cell, styles.selected] : [styles.cell]}>Optimistic</Text>
					  <Text style={c.myresponseaboutme == 24 ? [styles.cell, styles.selected] : [styles.cell]}>Pleased</Text>
					  <Text style={c.myresponseaboutme == 30 ? [styles.cell, styles.selected] : [styles.cell]}>Relaxed</Text>
					  <Text style={c.myresponseaboutme == 36 ? [styles.cell, styles.selected] : [styles.cell]}>Calm</Text>
				  </View>
				  <View style={[styles.leftcolumn]}>
					  <Image style={styles.gridimage} src={yellowcat}/>
					  <Text style={[styles.gridspace1]}></Text>
					  <Image style={styles.gridimage} src={rightarrow}/>
				  </View>
			</View>
			<View style={styles.headertableleft}>
			  <Text style={[styles.gridspacevert3]}></Text>
			  <Image style={styles.gridimagevert} src={downarrow}/>
			  <Text style={[styles.gridspacevert2]}></Text>
			  <Image style={styles.gridimagevert} src={greencat}/>
			</View>
		</View>
		<View style={styles.panel6}>
			<Text>THEY said you were:</Text>
			<Text style={styles.text}></Text>
			<View style={styles.headertable}>
			  <Text style={[styles.gridspacevert]}></Text>
			  <Image style={styles.gridimagevert} src={redcat}/>
			  <Text style={[styles.gridspacevert2]}></Text>
			  <Image style={styles.gridimagevert} src={uparrow}/>
			</View>
			<View style={styles.table}>
				  <View style={[styles.leftcolumn]}>
					  <Text style={[styles.gridspace]}></Text>
					  <Image style={styles.gridimage} src={leftarrow}/>
					  <Text style={[styles.gridspace1]}></Text>
					  <Image style={styles.gridimage} src={bluecat}/>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 1) ? [styles.cell, styles.selected] : [styles.cell]}>Angry {c.opponents.filter(item => item.responseAboutMe == 1).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 1).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 7) ? [styles.cell, styles.selected] : [styles.cell]}>Disgusted {c.opponents.filter(item => item.responseAboutMe == 7).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 7).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 13) ? [styles.cell, styles.selected] : [styles.cell]}>Irritated {c.opponents.filter(item => item.responseAboutMe == 13).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 13).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 19) ? [styles.cell, styles.selected] : [styles.cell]}>Deflated {c.opponents.filter(item => item.responseAboutMe == 19).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 19).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 25) ? [styles.cell, styles.selected] : [styles.cell]}>Sad {c.opponents.filter(item => item.responseAboutMe == 25).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 25).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 31) ? [styles.cell, styles.selected] : [styles.cell]}>Pessimistic {c.opponents.filter(item => item.responseAboutMe == 31).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 31).length + ')' : null}</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 2) ? [styles.cell, styles.selected] : [styles.cell]}>Annoyed {c.opponents.filter(item => item.responseAboutMe == 2).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 2).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 8) ? [styles.cell, styles.selected] : [styles.cell]}>Contemptuous {c.opponents.filter(item => item.responseAboutMe == 8).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 8).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 14) ? [styles.cell, styles.selected] : [styles.cell]}>Pained {c.opponents.filter(item => item.responseAboutMe == 14).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 14).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 20) ? [styles.cell, styles.selected] : [styles.cell]}>Disappointed {c.opponents.filter(item => item.responseAboutMe == 20).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 20).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 26) ? [styles.cell, styles.selected] : [styles.cell]}>Despondent {c.opponents.filter(item => item.responseAboutMe == 26).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 26).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 32) ? [styles.cell, styles.selected] : [styles.cell]}>Apathetic {c.opponents.filter(item => item.responseAboutMe == 32).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 32).length + ')' : null}</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 3) ? [styles.cell, styles.selected] : [styles.cell]}>Fearful {c.opponents.filter(item => item.responseAboutMe == 3).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 3).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 9) ? [styles.cell, styles.selected] : [styles.cell]}>Surprised {c.opponents.filter(item => item.responseAboutMe == 9).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 9).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 15) ? [styles.cell, styles.selected] : [styles.cell]}>Confused {c.opponents.filter(item => item.responseAboutMe == 15).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 15).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 21) ? [styles.cell, styles.selected] : [styles.cell]}>Doubtful {c.opponents.filter(item => item.responseAboutMe == 21).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 21).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 27) ? [styles.cell, styles.selected] : [styles.cell]}>Disheartened {c.opponents.filter(item => item.responseAboutMe == 27).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 27).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 33) ? [styles.cell, styles.selected] : [styles.cell]}>Complacent {c.opponents.filter(item => item.responseAboutMe == 33).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 33).length + ')' : null}</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 4) ? [styles.cell, styles.selected] : [styles.cell]}>Awed {c.opponents.filter(item => item.responseAboutMe == 4).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 4).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 10) ? [styles.cell, styles.selected] : [styles.cell]}>Keen {c.opponents.filter(item => item.responseAboutMe == 10).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 10).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 16) ? [styles.cell, styles.selected] : [styles.cell]}>Interested {c.opponents.filter(item => item.responseAboutMe == 16).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 16).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 22) ? [styles.cell, styles.selected] : [styles.cell]}>Focused {c.opponents.filter(item => item.responseAboutMe == 22).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 22).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 28) ? [styles.cell, styles.selected] : [styles.cell]}>Thoughtful {c.opponents.filter(item => item.responseAboutMe == 28).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 28).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 34) ? [styles.cell, styles.selected] : [styles.cell]}>Tranquil {c.opponents.filter(item => item.responseAboutMe == 34).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 34).length + ')' : null}</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 5) ? [styles.cell, styles.selected] : [styles.cell]}>Triumphant {c.opponents.filter(item => item.responseAboutMe == 5).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 5).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 11) ? [styles.cell, styles.selected] : [styles.cell]}>Hopeful {c.opponents.filter(item => item.responseAboutMe == 11).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 11).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 17) ? [styles.cell, styles.selected] : [styles.cell]}>Amused {c.opponents.filter(item => item.responseAboutMe == 17).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 17).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 23) ? [styles.cell, styles.selected] : [styles.cell]}>Contented {c.opponents.filter(item => item.responseAboutMe == 23).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 23).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 29) ? [styles.cell, styles.selected] : [styles.cell]}>Satisfied {c.opponents.filter(item => item.responseAboutMe == 29).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 29).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 35) ? [styles.cell, styles.selected] : [styles.cell]}>Composed {c.opponents.filter(item => item.responseAboutMe == 35).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 35).length + ')' : null}</Text>
				  </View>
				  <View style={[styles.column]}>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 6) ? [styles.cell, styles.selected] : [styles.cell]}>Elated {c.opponents.filter(item => item.responseAboutMe == 6).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 6).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 12) ? [styles.cell, styles.selected] : [styles.cell]}>Happy {c.opponents.filter(item => item.responseAboutMe == 12).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 12).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 18) ? [styles.cell, styles.selected] : [styles.cell]}>Optimistic {c.opponents.filter(item => item.responseAboutMe == 18).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 18).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 24) ? [styles.cell, styles.selected] : [styles.cell]}>Pleased {c.opponents.filter(item => item.responseAboutMe == 24).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 24).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 30) ? [styles.cell, styles.selected] : [styles.cell]}>Relaxed {c.opponents.filter(item => item.responseAboutMe == 30).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 30).length + ')' : null}</Text>
					  <Text style={c.opponents.some(item => item.responseAboutMe == 36) ? [styles.cell, styles.selected] : [styles.cell]}>Calm {c.opponents.filter(item => item.responseAboutMe == 36).length > 1 ? '(x' + c.opponents.filter(item => item.responseAboutMe == 36).length + ')' : null}</Text>
				  </View>
				  <View style={[styles.leftcolumn]}>
					  <Image style={styles.gridimage} src={yellowcat}/>
					  <Text style={[styles.gridspace1]}></Text>
					  <Image style={styles.gridimage} src={rightarrow}/>
				  </View>
			</View>
			<View style={styles.headertableleft}>
			  <Text style={[styles.gridspacevert3]}></Text>
			  <Image style={styles.gridimagevert} src={downarrow}/>
			  <Text style={[styles.gridspacevert2]}></Text>
			  <Image style={styles.gridimagevert} src={greencat}/>
			</View>
		</View>
      </View>
		<Text style={styles.text}></Text>
	  <Text style={[styles.scoretext]}>Your indexed score is: {c.myemotionscore}%</Text>
		<View style={styles.footerLeft}>
			  <Text style={[styles.smallprint]}>Highly Confidential © Copyright {new Date().getFullYear()} Savage Macbeth Limited</Text>
		</View>
		<View style={styles.footerRight}>
		</View>
	</Page>
    <Page size="A4" orientation="landscape" style={styles.whitebody}>
      <Text style={styles.smallprint}>
        Savage Macbeth Commercial Conflict Resolution Programme
      </Text>
      <Text style={styles.title} render={({ pageNumber }) => (
        `${pageNumber} . Emotion Awareness Tool report – understanding THEIR emotions`
      )} />
      <Text style={styles.subtitle}>{selected_attendee.enrolledUser.first_name} {selected_attendee.enrolledUser.last_name}, {selected_scenario.name}, {c.name}</Text>
	  <View style={styles.panelPage2}>
			<View style={[styles.table, styles.panelPage2Inner]}>
				  <View style={[styles.column, styles.column1]}>
					  <Text style={styles.row1celllarger}>You said they were:</Text>
					  <Text style={styles.row2cell}></Text>
					  <Text style={styles.row3cell}></Text>
					  <Text style={styles.row4celllarger}>They said they were:</Text>
					  <Text style={styles.row5cell}></Text>
					  <Text style={styles.row6cell}>Your score</Text>
				  </View>
	{c.opponents.map((o, oi) => (
				<>
				  <View style={[styles.column, styles.textCenter]}>
					  <Image style={styles.row1cell} src={o.myResponseAboutYouColour == 'green' ? greencat2 : o.myResponseAboutYouColour == 'red' ? redcat2 : o.myResponseAboutYouColour == 'yellow' ? yellowcat2 : o.myResponseAboutYouColour == 'blue' ? bluecat2 : greycat2}/>
					  <Text style={[styles.row2cell, styles.textCenter]}>{o.myResponseAboutYou}</Text>
					  <Image style={[styles.row3cell, styles.textCenter]} src={transitionarrow}/>
					  <Image style={[styles.row4cell, styles.textCenter]} src={o.yourResponseAboutYouColour == 'green' ? greencat2 : o.yourResponseAboutYouColour == 'red' ? redcat2 : o.yourResponseAboutYouColour == 'yellow' ? yellowcat2 : o.yourResponseAboutYouColour == 'blue' ? bluecat2 : greycat2}/>
					  <Text style={[styles.row5cell, styles.textCenter]}>{o.yourResponseAboutYou}</Text>
					  <Text style={[styles.row6cell, styles.textCenter]}>{o.score}%</Text>
				  </View>
				</>
          ))}
			</View>
      </View>
		<Text style={styles.text}></Text>
	  <Text style={[styles.scoretext]}>Your score is: {c.theiremotionscore}%</Text>
		<View style={styles.footerLeft}>
			  <Text style={[styles.smallprint]}>Highly Confidential © Copyright {new Date().getFullYear()} Savage Macbeth Limited</Text>
		</View>
		<View style={styles.footerRight}>

		</View>
    </Page>
	</>
          ))}
    <Page size="A4" orientation="landscape" style={styles.body}>
      <Text style={styles.smallprint}>
        Savage Macbeth Commercial Conflict Resolution Programme
      </Text>
      <Text style={styles.resultsTitle} >Results – how others’ read my emotions</Text>
      <Text style={styles.resultsSubtitle}>If you achieved a score of up to 50% </Text>
	  <Text style={styles.resultsText}>You seem quite difficult for others to read. There are a couple of things to be aware of if you scored in this bracket – how are others’ scores spread? If the scores appear random, this may indicate that they need to improve their ability in assessing other people’s emotions.</Text><Text style={styles.resultsText}>Are others’ scores concentrated in a distinct area or quadrant – some distance from where you scored your emotions? If so, this may indicate that the emotions you’re outwardly displaying are very different from those that you’re feeling.</Text><Text style={styles.resultsText}>
		If your emotions are extreme, do you need to go back and identify/regulate the emotions you’re currently feeling and moderate them accordingly?</Text><Text style={styles.resultsText}>
		The great news is that you can make some significant gains in the way others perceive you. Consider experimenting with how open you are about the information you disclose and how you feel about it. Reflect afterwards as to how well this worked and apply accordingly in the future.
		</Text>
      <Text style={styles.resultsSubtitle}>If you achieved a score of 51% – 75%</Text>
	  <Text style={styles.resultsText}>You have a reasonably consistent approach to how you feel and what you display.</Text><Text style={styles.resultsText}>To improve this still further, can you identify where the exceptions are to this? Are there any repeatable patterns? Are you consciously displaying slightly different emotions to the ones that others’ see? Is this intentional – e.g. I’m ‘irritated’, but want to be seen as ‘calm’ – what are the upsides and downsides to this within the context of the conflict? Being seen as ‘elated’ when actually you feel ‘contemptuous’ may encourage the other side to take a wrong course of action. Ask yourself, ‘Am I structuring the expectations of the other side appropriately?’</Text><Text style={styles.resultsText}>When away from face-to-face engagement, reflect objectively about how you feel - are past events affecting how you express emotions in the present? Try and ensure you can articulate them. If appropriate, consider sharing these when you re-engage, it could help you to get even better deals.</Text>
      <Text style={styles.resultsSubtitle}>If you achieved a score of 76% – 100%</Text>
	  <Text style={styles.resultsText}>You appear to be an open book – being this easy to read it should be relatively easy for the other side to understand your priorities, interests and boundaries.</Text><Text style={styles.resultsText}>Be careful of full disclosure of the more extreme emotions – e.g. showing ‘elation’ to the agreement of deal for which the other side are ‘doubtful’, may erode their perception of the deal – this could cause mistrust or even scope creep later.</Text><Text style={styles.resultsText}>If you can get your conflict (end game) management right this will really help to preserve those longer-term relationships. Consider your emotions within the context of deal closure – ‘should this be said?, should this be said by me?, should this be said by me now?’.</Text>
		<View style={styles.footerLeft}>
			  <Text style={[styles.smallprint]}>Highly Confidential © Copyright {new Date().getFullYear()} Savage Macbeth Limited</Text>
		</View>
		<View style={styles.footerRight}>
		</View>
    </Page>
    <Page size="A4" orientation="landscape" style={styles.greenbody}>
      <Text style={styles.smallprint}>
        Savage Macbeth Commercial Conflict Resolution Programme
      </Text>
      <Text style={styles.resultsTitle} >Results – how I read others’ emotions</Text>
      <Text style={styles.resultsSubtitle}>If you achieved a score of up to 50% </Text>
	  <Text style={styles.resultsText}>We didn’t say this was going to be easy, but you’ve made a positive first step. Some people are very skilled at ‘hiding’ how they really feel.</Text><Text style={styles.resultsText}>The great news is that the exercises and information that you will learn can really help you to improve how well you read, engage and manage relationships in the future. Continuing to practice the skills will also help you to get better.</Text><Text style={styles.resultsText}>The benefits of these skills can help you to engage, regulate and strengthen your future relationships from a business (and potentially a personal) perspective. Try them out, they can make a real difference. Take the time to summarise and engage in reflective listening, this should help you to review your thoughts on the emotions on display and give you second chance.</Text>
      <Text style={styles.resultsSubtitle}>If you achieved a score of 51% – 75%</Text>
	  <Text style={styles.resultsText}>Your score is within the banding that the majority of E.A.T. participants are. To understand your score better, study the spread of the results – how close are they?</Text><Text style={styles.resultsText}>Is there a pattern which shows a particular ‘emotion’ or quadrant that is/are particularly difficult for you? Consider which form of communication might help best improve the results – spotting different facial expressions, changes in body language, or the verbal cues/intonation used. Pay close attention to the relevant information and exercises.</Text><Text style={styles.resultsText}>Focus on the key areas for the exercises that are going to follow, such as picking up on micro-expressions and voice tone & intonation. </Text>
      <Text style={styles.resultsSubtitle}>If you achieved a score of 76% – 100%</Text>
	  <Text style={styles.resultsText}>You’ve really got the hang of this, great work! Here’s how you can get even better: </Text><Text style={styles.resultsText}>Think about the times you find it most difficult to read others – If you encounter intense emotions (especially if you’re feeling high intensity or more adversarial e.g. ‘anger’) it can be more difficult to remain objective and accurately assess how people on the other side really feel.</Text><Text style={styles.resultsText}>To counteract this, continue to identify and self-regulate your emotions, aim to ‘come down’ from the extremes to remain objective.</Text>
		<View style={styles.footerLeft}>
			  <Text style={[styles.smallprint]}>Highly Confidential © Copyright {new Date().getFullYear()} Savage Macbeth Limited</Text>
		</View>
		<View style={styles.footerRight}>
		</View>
	</Page>
  </Document>
);

const MyDoc = () => (
(pdf_data == null) ? null : 
<Document>
	{pdf_data.scenario_points == null ? null : pdf_data.scenario_points.map((c, i) => (
	<>
	</>
          ))}
}
</Document>);

    return(
		pdf_data == null ? show_as_link == "false" ? null : <Text onClick={GeneratePDF} style={styles.linkstyle} >{selected_scenario.name.substring(0,5)}&nbsp;</Text> : 
		<PDFDownloadLink document={<MyDocument />} fileName={selected_attendee.enrolledUser.first_name + " " + selected_attendee.enrolledUser.last_name + " " + selected_scenario.name + ".pdf"} style={styles.imageWrapper}>
			{show_as_link == "false" ? <div style={{paddingTop: 30}}><div><img src={pdficon} style={{width: 250, height: 188, borderStyle: 'solid', borderColor: '#ccc', borderWidth: 1}} /></div><Text style={styles.linkstyle} >{selected_attendee.enrolledUser.first_name} {selected_attendee.enrolledUser.last_name} {selected_scenario.name}.PDF</Text></div> : <div><Text style={styles.linkstyle} >{selected_scenario.name}&nbsp;</Text></div>}
		</PDFDownloadLink>
    )
}

Font.register({
  family: 'Noe',
  src: font
});

const styles = StyleSheet.create({
  whitebody: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 15,
	backgroundColor: '#ffffff',
  },
  body: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 15,
	backgroundColor: '#ffc747',
  },
  greenbody: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 15,
	backgroundColor: Colors.green,
  },
  title: {
    margin: 12,
    fontSize: 24,
    textAlign: 'left',
    fontFamily: 'Noe'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    marginHorizontal: 36,
    fontFamily: 'Noe'
  },
  scoretext: {
    fontSize: 18,
    fontFamily: 'Noe',
	textAlign: 'center'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Noe'
  },
  smallprint: {
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Noe'
  },
  resultsTitle: {
    marginTop: 24,
    marginBottom: 4,
    marginHorizontal: 20,
    fontSize: 24,
	fontWeight: 'bold',
    textAlign: 'left',
    fontFamily: 'Noe'
  },
  resultsSubtitle: {
    marginTop: 4,
    marginBottom: 2,
    marginHorizontal: 20,
    fontSize: 16,
    textAlign: 'left',
    fontFamily: 'Noe'
  },
  resultsText: {
    marginTop: 0,
    marginBottom: 2,
    marginHorizontal: 20,
    fontSize: 12,
    textAlign: 'left',
    fontFamily: 'Noe'
  },
  gridimage: {
	height: 60,
  },
  gridspaceimage: {
	height: 22,
  },
  gridspacevert: {
	  width: 50,
  },
  gridspace: {
	  height: 59,
  },
  gridspace1: {
	  height: 0,
  },
  gridspacevert2: {
	  width: 35,
  },
  gridimagevert: {
	  width: 65,
  },
  gridimagevert2: {
	  width: 45,
  },
  gridspacevert3: {
	  width: 151,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  panel12: {
	display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    justifyContent: 'flex-start',
    width:'100%',
  },
  panel6: {
	  margin: 12,
	  width: 580,
  },
	headertable: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	headertableleft: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'right',
		alignContent: 'right',
		textAlign: 'right',
	},
	table: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	tableRow:{
		display: 'flex',
		flexDirection: 'row',
	},
	cell: {
		padding: 5,
		paddingTop: 10,
		fontSize: 5,
		borderWidth: 1,
		borderColor: '#ffffff',
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		textAlign: 'center',
		flexWrap: 'wrap',
		backgroundColor: '#000000',
		color: '#ffffff',
		height: 30,
		width: 45,
	},
	leftcolumn: {
		justifyContent: 'bottom',
		alignContent: 'bottom',
	},
	selected: {
		backgroundColor: 'transparent',
		color: '#000000',
	},
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  page1Palette1: {
	  position: 'absolute',
	  top: 214,
	  left: 76,
	  right: 50,
	  bottom: 50,
	  width: 270,
	  height: 179,
  },
  page1Palette2: {
	  position: 'absolute',
	  top: 214,
	  left: 482,
	  right: 50,
	  bottom: 50,
	  width: 270,
	  height: 179,
  },
  footerLeft: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 20,
    right: 0,
    textAlign: 'left',
  },
  footerRight: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    right: 20,
    textAlign: 'right',
  },
  panelPage2: {
	  border: 1,
	  margin: 20,
  },
  panelPage2Inner: {
	  margin: 20,
  },
  row1cell: {
	  height: 50,
	  width: 63,
  },
  row1celllarger: {
	  height: 50,
	  width: 103,
  },
  row2cell: {
	  height: 25,
	  fontSize: 9,
	  width: '100%',
  },
  row3cell: {
	  height: 35,
	  width: 63,
  },
  row4cell: {
	  height: 50,
	  width: 63,
  },
  row4celllarger: {
	  height: 50,
	  width: 103,
  },
  row5cell: {
	  height: 25,
	  fontSize: 9,
	  width: '100%',
  },
  row6cell: {
	  height: 25,
	  fontSize: 16,
	  width: '100%',
  },
  textCenter: {
		justifyContent: 'center',
		alignContent: 'center',
		textAlign: 'center',
  },
  column1: { 
	width: 140,
	  textAlign: 'left',
  },
  '@media orientation: landscape': {
      width: 400,
    },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center',
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff',
	  cursor: 'pointer',
  }
});