import React, { useEffect, useState  } from 'react'
import { StyleSheet, View, Text, Image, FlatList, TouchableOpacity } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import PrintPDFReport from '../screens/PrintPDFReport'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'

import '@expo/match-media'
// Unleash the demo :D
import { useMediaQuery } from "react-responsive";

import waitingimg from '../assets/loading.gif';
import pdficon from '../assets/pdf-icon.png';

import Api from '../components/Api'

interface SplashProps {
  navigation: any
  onContinue: Function
  gotopage: Function
  execute_review_point: Function
  viewPDFReport: Function
}


export default function Splash ({ navigation, onContinue, gotopage, execute_review_point, viewPDFReport }: SplashProps) {
	const [review_point, SetReviewPoint] = useState(null)
	const [scenario_point, SetScenarioPoint] = useState(null)
	const [scenario, SetScenario] = useState(null)
	const [attendee, SetAttendee] = useState(null)
	const [sb_user, SetSBUser] = useState(null)
	const [scenarios, SetScenarios] = useState(null)
	const [group_member, SetGroupMember] = useState(null)
	
	var reviewPointChecker;

	const isTabletOrMobileDevice = useMediaQuery({    
		maxWidth: 750,
		// alternatively...
		query: "(max-width: 750px)"  
	  });
  
	const onGetCurrentReviewPointDone = (review_point) => {
		SetReviewPoint(review_point)
		const query = new URLSearchParams(location.search);
		new Api().GetGroupMemberFromEnrollmentId(query.get('id'), review_point.id, onGetGroupMemberFromEnrollmentIdDone, onGetGroupMemberFromEnrollmentIdFail)
		new Api().getScenarioPoint(review_point.scenario_point_id, onGetScenarioPointDone)
	}
	
	const onGetAttendeeDone = (attendee) => {
		SetAttendee(attendee)
	}

	const onGetScenarioPointDone = (scenario_point) => {
		SetScenarioPoint(scenario_point)
		new Api().getScenario(scenario_point.scenario_id, onGetScenarioDone)
	}

	const onGetScenarioDone = (scenario) => {
		SetScenario(scenario)
	}

	const onGetCurrentReviewPointFail = () => {
		SetScenario(null)
		SetReviewPoint(null)
		SetScenarioPoint(null)
	}
	
	const onGetUserFromEnrollmentIdDone = (sb_user) => {
		console.log(sb_user)
		SetSBUser(sb_user)
	}

	const onGetScenariosByAttendeeIdDone = (scenarios) => {
		if (scenarios.length > 0)
		{
			SetScenarios(scenarios)
		}
		else
		{
			SetScenarios(null)
		}
	}

	const onGetUserFromEnrollmentIdFail = () => {

	}

	const doExecuteReviewPoint = () => {
		clearInterval(reviewPointChecker);
		execute_review_point(group_member, review_point)
	}
	
	const onGetGroupMemberFromEnrollmentIdDone = (group_member) => {
		SetGroupMember(group_member)
	}

	const onGetGroupMemberFromEnrollmentIdFail = () => {

	}

	const checkForReviewPoint = () => {
		const query = new URLSearchParams(location.search);
		new Api().GetCurrentReviewPoint(query.get('id'), onGetCurrentReviewPointDone, onGetCurrentReviewPointFail)

		new Api().GetScenariosByAttendeeId(query.get('id'), onGetScenariosByAttendeeIdDone)

	}
	
	const doViewPDFReport = (scenario) => {
		viewPDFReport(group_member.enrollment_id, scenario)
	}
	
	
	useEffect(() => {
		/*if(reviewPointChecker == undefined) { 
			reviewPointChecker = setInterval(checkForReviewPoint, 3000);
		}
		console.log(reviewPointChecker);*/
		const query = new URLSearchParams(location.search);
		checkForReviewPoint();
		new Api().getAttendee(query.get('id'), onGetAttendeeDone)
		new Api().GetUserFromEnrollmentId(query.get('id'), onGetUserFromEnrollmentIdDone, onGetUserFromEnrollmentIdFail)
	}, [])

	useEffect(() => {
		return () => {
			clearInterval(reviewPointChecker);
		};
	}, [])

	  if (sb_user == null)
	  {
		  return (
		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar navigation={navigation}></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Heading fontsize={44} >Loading...</Heading>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={15}/>
				<View style={styles.imageWrapper} >
					<Image style={styles.rowImage} alt="Waiting..." source={waitingimg}></Image>
				</View>
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>
		  )
	  }
	  else if (scenario == null || scenario_point == null || review_point == null)
	  {
  return (
		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar navigation={navigation} name={sb_user.first_name + " " + sb_user.last_name}></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Para>Welcome to E.A.T. – our Emotion Awareness Tool<br />E.A.T. is an emotional intelligence tool designed to measure how good we are at recognising our own emotions as well as those on the other side of the table in our negotiations.</Para>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<View style={styles.boxContainer}>
					{isTabletOrMobileDevice ? 
					
					<View style={styles.leftBox1}>
						<Para>Thank you for visiting E.A.T.</Para>
						<Para>You currently have no tasks to complete.</Para>
						<Para>When you have finished assessing each E.A.T. scenario, your pdf report will appear below ready for you to download.</Para>
					</View>
					:
					<View><View style={styles.leftBox}>
						<Para>Thank you for visiting E.A.T.</Para>
						<Para>You currently have no tasks to complete.</Para>
						<Para>When you have finished assessing each E.A.T. scenario, your pdf report will appear below ready for you to download.</Para>
					</View>
					<View style={styles.rightBox}>
						  <Image style={styles.masks} source={require('../assets/masks.png')}></Image>
					</View></View>}
				</View>
				<VerticalSpace height={20} />
				{(scenarios != null) ? <Para bold={true} fontSize={20}>Click on report to download:</Para> : null}
				<FlatList
				  data={scenarios}
				  style={styles.table}
				  numColumns={1}
				  renderItem={({item: the_scenario}) => <PrintPDFReport selected_attendee={attendee} selected_scenario={the_scenario} show_as_link={'false'} />}
				/>		  
				{scenarios != null ? <VerticalSpace height={20} /> : null}
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>
		)
	  }
	  else
	  {
		  return (
		<KeyboardAwareScrollView style={styles.outerContainer}>
		  <View style={styles.innerContainer}>
			<NavigationBar navigation={navigation} name={sb_user.first_name + " " + sb_user.last_name}></NavigationBar>
		  </View>
		  <View style={styles.container, styles.greenStripe}>
			<View style={[styles.innerContainer, styles.container]}>
				<Para>Welcome to E.A.T. – our Emotion Awareness Tool<br />E.A.T. is an emotional intelligence tool designed to measure how good we are at recognising our own emotions as well as those on the other side of the table in our negotiations.</Para>
			</View>
		  </View>
		  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />

					{isTabletOrMobileDevice ? 
					
				<View style={styles.boxContainer}>
					<View style={styles.leftBox1}>
						<Para>This scenario is: {scenario.name}</Para>
						<Para>This review point is: {scenario_point.name}</Para>
						<Para>You will be given 4 quadrants to choose from based on whether the emotion you are scoring (yours or theirs) is collaborative or adversarial and how intense it feels. You will then have an option to choose a specific emotion that best represents that which you are experiencing.</Para>
						<Para>For greatest accuracy answer quickly (gut feel) and honestly. Good luck!</Para>
					</View>
				</View>
				
					:
				<View style={styles.boxContainer}>
					<View style={styles.leftBox}>
						<Para>This scenario is: {scenario.name}</Para>
						<Para>This review point is: {scenario_point.name}</Para>
						<Para>You will be given 4 quadrants to choose from based on whether the emotion you are scoring (yours or theirs) is collaborative or adversarial and how intense it feels. You will then have an option to choose a specific emotion that best represents that which you are experiencing.</Para>
						<Para>Once you’ve completed the task you will return to this screen</Para>
						<Para>For greatest accuracy answer quickly (gut feel) and honestly. Good luck!</Para>
					</View>
					<View style={styles.rightBox}>
						  <Image style={styles.masks} source={require('../assets/masks.png')}></Image>
					</View>
				</View>}

				<VerticalSpace height={20} />
				<VerticalSpace height={20} />
				<DefaultButton text={'Start'} onPress={() => doExecuteReviewPoint()} />
				<VerticalSpace height={20} />
				{(scenarios != null) ? <Para bold={true} fontSize={20}>Click on report to download:</Para> : null}
				<FlatList
				  data={scenarios}
				  style={styles.table}
				  numColumns={1}
				  renderItem={({item: the_scenario}) => <PrintPDFReport selected_attendee={attendee} selected_scenario={the_scenario} show_as_link={'false'} />}
				/>		  
				{scenarios != null ? <VerticalSpace height={20} /> : null}
			</View>
		  </View>
		  <FooterBar />
		</KeyboardAwareScrollView>
		)
	  }
}

const styles = StyleSheet.create({
  table: {
	  width: 250,
  },
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
	  paddingTop: 20,
	  paddingBottom: 20,
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  boxContainer: {
    display: 'flex',
	maxWidth: 1000,
    flexDirection: 'row',
  },
  leftBox: {
	  float: 'left',
	  width: '70%',
  },
  leftBox1: {
	  width: '100%',
  },
  rightBox: {
	  float: 'right',
	  width: '20%',
	  textAlign: 'right',
  },
  masks: {
    height: 115,
    width: 200,
	marginTop: 3,
  },

  
})
