import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView, Text, FlatList } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ContentEditable from 'react-contenteditable'
import Clipboard from '@react-native-clipboard/clipboard';
import { ToastContainer, toast } from 'react-toastify';
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import PrintPDFReport from '../screens/PrintPDFReport'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Table from '../components/Table'
import Moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Api from '../components/Api'

import 'react-toastify/dist/ReactToastify.css';

interface ViewEventProps {
  navigation: any
  onContinue: Function
  selected_event: any
  gotopage: Function
  removeAttendee: Function
  attendees: any
  scenarios: any
  removeScenario: Function
  viewScenario: Function
  showScenarioResults: Function
  viewReviewPoint: Function
  toggleReviewPoint: Function
  StartEditing: Function
}


export default function ViewEvent ({ navigation, onContinue, selected_event, gotopage, attendees, removeAttendee, scenarios, removeScenario, viewScenario, showScenarioResults, viewReviewPoint, toggleReviewPoint, StartEditing }: ViewEventProps) {
	const [currentReviewPoint, SetCurrentReviewPoint] = useState(null)
	const [currentScenarioPoint, SetCurrentScenarioPoint] = useState(null)
	const [currentScenario, SetCurrentScenario] = useState(null)
	const [show_page, SetShowPage] = useState(false)
	const [review_points, SetReviewPoints] = useState(null)
	var reviewPointChecker;
	
	const linkURL = "https://eat.savagemacbeth.com/?id="

	function handleAllocationNumberChange(attendee_id, newnumber) {
		new Api().UpdateEnrollmentAllocationNumber(attendee_id, newnumber)
  }

const copyToClipboard = (url) => {
    Clipboard.setString(url);
	showToast('Link copied')
  };
  
  const showToast = (msg) => {
	  window.scrollTo(0, 0);
	toast.success(msg, {
		  position: toast.POSITION.TOP_CENTER
		});
  };

  const attendee_columns = React.useMemo(
     () => [
       {
			Header: "First name",
			accessor: "enrolledUser.first_name",
			filterable: true
		},
		{
			Header: "Last name",
			accessor: "enrolledUser.last_name",
			filterable: true
		},
		{
			Header: "Email",
			accessor: "enrolledUser.email",
			filterable: true
		},
		{
			Header: "Allocation",
			accessor: "allocation_number",
			Cell: ( props ) => (
				  <div>
					<Text>{props.row.original.allocation_number}</Text>
					</div>
				  )
		},
		{
			Header: "",
			accessor: "id",
Cell: ( props ) => (
	  <div>
		<a href="#" onClick={e => handleInviteAttendee(props.row.original.id)}>Invite</a>
		<span> | </span>
		<a href="#" onClick={e => copyToClipboard(linkURL + props.row.original.id)}>Copy to clipboard</a>
		<span> | </span>
		<a href="#" onClick={e => handleDeleteAttendee(props.row.original.id)}>Delete</a>
		</div>
      )
	  },
		{
			Header: "Invited",
			Cell: (props) => (
			  <div>
			  {props.row.original.dateInvited === null ? null : Moment(props.row.original.dateInvited).format("DD/MM/YYYY")}
				</div>
			)
		},
		{
			Header: "Reports",
			Cell: (props) => (
				<FlatList
				  data={scenarios}
				  style={styles.table}
				  numColumns={8}
				  renderItem={({item: the_scenario}) => <PrintPDFReport selected_attendee={props.row.original} selected_scenario={the_scenario} show_as_link={'true'} />}
				/>		  
			)
		},
     ],
     []
   )

   const scenario_columns = React.useMemo(
     () => [
       {
			Header: "Name",
			accessor: "name",
			filterable: true,
			Cell: ( props ) => (
				  <div>
					<a href="#" onClick={e => doViewScenario(props.row.original)}>{props.row.original.name}</a>
				  </div>
			)
		},
		{
			Header: "",
			accessor: "id",
Cell: ( props ) => (
	  <div>
			  {props.row.original.release_results == "true" ? <a href="#" onClick={() => doShowScenarioResults(props.row.original.id, 'false')}>Hide report</a> : <a href="#" onClick={() => doShowScenarioResults(props.row.original.id, 'true')}>Show report</a>}
		<span> | </span>
		<a href="#" onClick={e => doRemoveScenario(props.row.original.id)}>Delete</a>
		</div>
      )
	  },
     ],
     []
   )

   const review_point_columns = React.useMemo(
     () => [
       {
			Header: "Name",
			accessor: "name",
			filterable: true,
			Cell: ( props ) => (
				  <div>
					<a href="#" onClick={e => toggleReviewPoint(props.row.original.id, 'true')}>{props.row.original.name}</a>
				  </div>
			)
		}
     ],
     []
   )

  const updateName = (text: string) => {
	  setName(text)
  }
  
  function handleDeleteAttendee(id) {
	  removeAttendee(id)
  }
  
  function doRemoveScenario(id) {
		confirmAlert({
		  title: 'Confirm delete',
		  message: 'Are you sure you want to do this.',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => removeScenario(id)
			},
			{
			  label: 'No'
			}
		  ]
		});
  }
  
  	function doViewScenario(id) {
		viewScenario(id)
	}

  function doShowScenarioResults(id, show) {
	  showScenarioResults(id, show)
  }
  
  function handleInviteAttendee(id) {
	  new Api().InviteAttendee(id, onInviteAttendeeDone, onInviteAttendeeFailed)
  }
  
  const toggleEdit = () => {
	  StartEditing()
  }

  function handleInviteAllAttendees(event_id) {
		confirmAlert({
		  title: 'Confirm invite all',
		  message: 'Are you sure you want to do this.',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => new Api().InviteAllAttendees(event_id, onInviteAttendeeDone, onInviteAttendeeFailed)
			},
			{
			  label: 'No'
			}
		  ]
		});
  }
  
	useEffect(() => {
		new Api().GetCurrentReviewPointBasedOnEvent(selected_event.id, onGetCurrentReviewPointBasedOnEventDone)
		if(reviewPointChecker == undefined) { 
			reviewPointChecker = setInterval(checkForReviewPoint, 3000);
		}
	}, [])

	useEffect(() => {
		return () => {
			clearInterval(reviewPointChecker);
		};
	}, [])

	const checkForReviewPoint = () => {
		new Api().GetCurrentReviewPointBasedOnEvent(selected_event.id, onGetCurrentReviewPointBasedOnEventDone)
	}

	function onGetCurrentReviewPointBasedOnEventDone(current_review_point){
		if (current_review_point != null)
		{
			SetCurrentReviewPoint(current_review_point)
			new Api().getScenarioPoint(current_review_point.scenario_point_id, onGetScenarioPointDone)
		}
		else
		{
			SetCurrentReviewPoint(null)
			new Api().ListAllEventReviewPoints(selected_event.id, onGetEventReviewPointsDone)
		}
	}

	function onGetScenarioPointDone(scenario_point){
		SetCurrentScenarioPoint(scenario_point)
		new Api().getScenario(scenario_point.scenario_id, onGetScenarioDone)
	}

	function onGetScenarioDone(scenario){
		SetCurrentScenario(scenario)
		new Api().ListAllEventReviewPoints(selected_event.id, onGetEventReviewPointsDone)
	}
	
	function onGetEventReviewPointsDone(event_review_points){
		SetReviewPoints(event_review_points)
		
		SetShowPage(true)
	}
	
	const handleGoToCurrentReviewPoint = (review_point) => {
		viewReviewPoint(review_point)
	}

  const onInviteAttendeeDone = () => {
	showToast('Email sent')
  }
  
  const onInviteAttendeeFailed = () => {
		toast.error('There was a problem sending the email', {
		  position: toast.POSITION.TOP_CENTER
		});
	}

	function handleChangeName(scenario_id, newname) {
		selected_event.name = newname;
		new Api().UpdateEventName(scenario_id, newname)
  }
  
  return (
  <View>
  {(show_page == true) ? <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Programme: 	
				<Text>{selected_event.name}</Text>
			</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listevents')}>List Programmes</a> -> View Programme</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('addattendee')} text={'Add Attendee'} />
				<VerticalSpace height={20} />
				<Text style={styles.sectionHeader}>Attendees</Text>
				<VerticalSpace height={20} />
				<DefaultButton onPress={e => handleInviteAllAttendees(selected_event.id)} text="Invite all attendees"/>
				<VerticalSpace height={20} />
				<ToastContainer />
				<Table data={attendees} columns={attendee_columns} hideSearch={true} />
				
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('addscenario')} text={'Add Scenario'} />
				<VerticalSpace height={20} />
				<Text style={styles.sectionHeader}>Scenarios</Text>
				<VerticalSpace height={20} />
				<Table data={scenarios} columns={scenario_columns} hideSearch={true} />
				<VerticalSpace height={20} />
				<View><Text style={styles.sectionHeader}>Current review point</Text>
				<VerticalSpace height={20} />
				{(currentReviewPoint != null && currentScenario != null) ? <Text><a href="#" onClick={e => handleGoToCurrentReviewPoint(currentReviewPoint)}>{currentScenario.name} -> {currentScenarioPoint.name} -> {currentReviewPoint.name}</a><br /><b>Responses:</b> {currentReviewPoint.responded_count} out of {currentReviewPoint.available_responses_count}<br /><a href="#" onClick={e => toggleReviewPoint(currentReviewPoint.id, 'false')}>Hide</a></Text> : <Text>None</Text>}
				</View>
				<VerticalSpace height={20} />
				{(review_points != null) ? <View><Text style={styles.sectionHeader}>Switch to</Text>
				<VerticalSpace height={20} />
				<Table data={review_points} columns={review_point_columns} hideSearch={true} />
				<VerticalSpace height={20} /></View> : null}
				<DefaultButton onPress={toggleEdit} text="Edit"/>
				<VerticalSpace height={40} />
				<DefaultButton onPress={onContinue} text="Return"/>
				<VerticalSpace height={20} />
		</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  : <Text>Loading</Text>}
  </View>
  )
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },  
})
