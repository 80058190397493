import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import ConditionalButton from '../components/ConditionalButton'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Api from '../components/Api'

interface EditUserProps {
  navigation: any
  onContinue: Function
  gotopage: Function
  user: any
}


export default function EditUser ({ navigation, onContinue, gotopage, user }: EditUserProps) {
  const [firstName, setFirstName] = useState(user.first_name)
  const [lastName, setLastName] = useState(user.last_name)
  const [email, setEmail] = useState(user.email)
  const [membershipNumber, SetMembershipNumber] = useState(user.membership_number)
  const [updating, SetUpdating] = useState(false)

  const canContinue = () => (firstName !== '' && lastName !== '' && email !== '' && membershipNumber !== '' && (!updating))
  
  const updateFirstName = (text: string) => {
	  setFirstName(text)
  }

  const updateLastName = (text: string) => {
	  setLastName(text)
  }

  const updateEmail = (text: string) => {
	  setEmail(text)
  }

  const updateMembershipNumber = (text: string) => {
	  SetMembershipNumber(text)
  }

  function doContinue()
  {
	  SetUpdating(true)
	  new Api().updateUser(user.id, firstName, lastName, email, membershipNumber, onUpdateDone)
	  
  }
  
  const onUpdateDone = () => {
	  onContinue()
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Edit User</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listusers')}>List Users</a> -> Edit User</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
      <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<LabeledTextField placeholder="Required" label="First name" onChange={updateFirstName} value={firstName}/>
				<LabeledTextField placeholder="Required" label="Last name" onChange={updateLastName} value={lastName}/>
				<LabeledTextField placeholder="Required" label="Email" onChange={updateEmail} value={email}/>
				<LabeledTextField placeholder="Required" label="Membership number" onChange={updateMembershipNumber} value={membershipNumber}/>
				<VerticalSpace height={40} />
				<ConditionalButton onPress={doContinue} condition={canContinue} text="Update"/>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('listusers')} text={'cancel'} />
				<VerticalSpace height={20} />
			</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  }
  
})
