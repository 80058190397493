import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import DefaultButton from '../components/DefaultButton'
import Heading from '../components/Heading'
import Para from '../components/Para'
import ContentEditable from 'react-contenteditable'
import LabeledTextField from '../components/LabeledTextField'
import VerticalSpace from '../components/VerticalSpace'
import NavigationBar from '../components/NavigationBar'
import FooterBar from '../components/FooterBar'
import Styles from '../constants/Styles'
import Colors from '../constants/Colors'
import Table from '../components/Table'
import Api from '../components/Api'
import { confirmAlert } from 'react-confirm-alert'; // Import

interface ViewScenarioPointProps {
  navigation: any
  onContinue: Function
  selected_scenario_point: any
  review_points: any
  gotopage: Function
  removeReviewPoint: Function
  viewReviewPoint: Function
  toggleReviewPoint: Function
}


export default function ViewScenarioPoint ({ navigation, onContinue, selected_scenario_point, review_points, gotopage, removeReviewPoint, viewReviewPoint, toggleReviewPoint }: ViewScenarioPointProps) {
	const [local_data, SetLocalData] = useState(review_points)
	var reviewPointChecker;

	useEffect(() => {
		if(reviewPointChecker == undefined) { 
			reviewPointChecker = setInterval(checkForReviewPoint, 3000);
		}
	}, [])

	useEffect(() => {
		return () => {
			clearInterval(reviewPointChecker);
		};
	}, [])

	const checkForReviewPoint = () => {
		new Api().ListReviewPoints(selected_scenario_point.id, onListReviewPointsDone)
	}
	
	const onListReviewPointsDone = (review_points) => {
		SetLocalData(review_points)
	}

   const columns = React.useMemo(
     () => [
       {
			Header: "Name",
			accessor: "name",
			filterable: true,
			Cell: ( props ) => (
				  <div>
					{props.row.original.currently_live == "true" ? <div style={{backgroundColor: Colors.green}}>
						<a href="#" onClick={e => doViewReviewPoint(props.row.original)}>{props.row.original.name}</a>
					  </div> : <div>
						<a href="#" onClick={e => doViewReviewPoint(props.row.original)}>{props.row.original.name}</a>
					  </div>}
				  </div>
				)
		},
       {
			Header: "Responses",
			accessor: "available_responses_count",
			filterable: true,
			Cell: ( props ) => (
				  <div>
					{props.row.original.responded_count} / {props.row.original.available_responses_count}
				  </div>
				)
		},
		{
			Header: "",
			accessor: "id",
Cell: ( props ) => (
				  <div>
					{props.row.original.currently_live == "true" ? <div style={{backgroundColor: Colors.green}}>
						<a href="#" onClick={() => doToggleReviewPoint(props.row.original.id, 'false')}>Hide</a>
						<span> | </span>
						<a href="#" onClick={e => doRemoveReviewPoint(props.row.original.id)}>Delete</a>
					  </div> : <div>
						<a href="#" onClick={() => doToggleReviewPoint(props.row.original.id, 'true')}>Show</a>
						<span> | </span>
						<a href="#" onClick={e => doRemoveReviewPoint(props.row.original.id)}>Delete</a>
					  </div>}
					
				  </div>


      )
	  },
     ],
     []
   )

  function doRemoveReviewPoint(id) {
		confirmAlert({
		  title: 'Confirm delete',
		  message: 'Are you sure you want to do this.',
		  buttons: [
			{
			  label: 'Yes',
			  onClick: () => removeReviewPoint(id)
			},
			{
			  label: 'No'
			}
		  ]
		});
  }
  
  	function doViewReviewPoint(id) {
		viewReviewPoint(id)
	}
	
  function doToggleReviewPoint(id, show) {
	  toggleReviewPoint(id, show)
  }

	function handleChangeName(scenario_point_id, newname) {
		selected_scenario_point.name = newname;
		new Api().UpdateScenarioPointName(scenario_point_id, newname)
  }

  return (
    <KeyboardAwareScrollView style={styles.outerContainer}>
	  <View style={styles.innerContainer}>
		<NavigationBar navigation={navigation}></NavigationBar>
	  </View>
	  <View style={styles.container, styles.greenStripe}>
		<View style={[styles.innerContainer, styles.container]}>
			<Heading fontsize={44} >Scenario point: 
				<ContentEditable
							innerRef={selected_scenario_point}
						  html={selected_scenario_point.name} // innerHTML of the editable div
						  disabled={false}       // use true to disable editing
						  onChange={(val) => handleChangeName(selected_scenario_point.id, val.target.value)} // handle innerHTML change
						  tagName='article' // Use a custom HTML tag (uses a div by default)
						/>
			</Heading>
			<div style={{color: '#000000', width: '100%'}}><a href='#' onClick={() => gotopage('welcome')}>Home</a> -> <a href='#' onClick={() => gotopage('listevents')}>List Programmes</a> -> <a href='#' onClick={() => gotopage('viewevent')}>View Programme</a> -> <a href='#' onClick={() => gotopage('viewscenario')}>View Scenario</a> -> View Scenario Point</div>
			<VerticalSpace height={10} />
		</View>
	  </View>
	  <View style={styles.container}>
			<View style={[styles.innerContainer, styles.container]}>
				<VerticalSpace height={20} />
				<DefaultButton onPress={() => gotopage('addreviewpoint')} text={'Add Review Type'} />
				<VerticalSpace height={20} />
				<Text style={styles.sectionHeader}>Review Types</Text>
				<VerticalSpace height={20} />
				<Table data={local_data} columns={columns} hideSearch={true} />
				<VerticalSpace height={40} />
				<DefaultButton onPress={onContinue} text="Return"/>
				<VerticalSpace height={20} />
		</View>
      </View>
	  <FooterBar />
    </KeyboardAwareScrollView>
  )
}


const styles = StyleSheet.create({
  container: {
    paddingHorizontal: Styles.standardPadding,
  },
  ScrollViewDriver: {
	  display: 'table',
		width: '100%'
  },
  tableData11: {
	  display: 'table-cell',
	  width: 50,
	  padding: 10
  },
  table: {
	  display: 'table-row'
  },
  tableData1: {
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  tableData1S: {
	  color: 'blue',
	  display: 'table-cell',
	  width: 70,
	  padding: 10
  },
  greenStripe: {
	  backgroundColor: Colors.green,
	  color: '#ffffff',
  },
  violetStripe: {
	  backgroundColor: Colors.violet,
	  color: '#ffffff',
  },
  innerContainer: {
	maxWidth: 1000,
    alignSelf: 'center',
    width: '100%',
  },
  outerContainer: {
    alignSelf: 'center',
    width: '100%',
	fontFamily: "NoeDisplayMedium",
  },
  imageWrapper: {
	  textAlign: 'center',
	  justifyContent: 'center',
    alignItems: 'center'
  },
  waitingimgstyle: {
	maxWidth: '200px'
  },
  linkstyle: {
	  color: '#0000ff'
  },
  sectionHeader: {
	  fontWeight: 'bold'
  },  
})
